import { sendPost } from './axios';

export const upload = (files: any) => {
  const formData = new FormData();

  formData.append('files', files.file);
  return sendPost('/upload', formData);
};

export const uploadMultiple = (files: any[]) => {
  const formData = new FormData();
  for (const file of files) {
    formData.append('files', file);
  }
  return sendPost('/upload', formData);
};
