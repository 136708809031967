import { Button, Checkbox, Col, Form, message, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getCurrentPayroll, getPayrollByMonth } from 'api/salary';
import { createOvertime, getListConstructionHaveTask, getRemainMonthlyOvertime } from 'api/timekeeping';
import { TimeKeepingType, TYPE_PICKER, UserRole } from 'common';
import { ListOvertimeType, MILLISECOND_A_HOUR } from 'common/const';
import { dateUtils } from 'common/dateUtils';
import AccessControl from 'components/AccessControl';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { handleErrorMessage } from 'helper';
import useIsMounted from 'hooks/useIsMounted';
import useProfile from 'hooks/useProfile';
import useProfileCompany from 'hooks/useProfileCompany';
import useRoleId from 'hooks/useRoleId';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';

const today = moment();
const yesterday = moment().add(-1, 'day');

interface IPropsTaskForm {
  type?: TimeKeepingType;
  handleClose: () => any;
}

const { Text } = Typography;

export const ModalOvertime = ({ handleClose }: IPropsTaskForm) => {
  const isMounted = useIsMounted();
  const [form] = useForm();
  const profile = useProfile();
  const { profileCompany } = useProfileCompany();
  const roleId = useRoleId();
  const [state, setState] = useSetState({
    loading: false,
    checkIn: null as any,
    checkOut: null as any,
    hasNight: !!profileCompany?.nightFrom && !!profileCompany?.nightTo,
    duration: 0,
    month: '',
    startDate: null,
    endDate: null,
    totalHourRemain: 0,
  });

  useEffect(() => {
    getCurrentPayroll()
      .then((res) => {
        setState({
          month: res?.data?.month,
        });
      })
      .catch(handleErrorMessage);

    form.setFieldsValue({
      nightFrom: profileCompany?.nightFrom,
      nightTo: profileCompany?.nightTo,
    });
  }, []);

  useEffect(() => {
    if (state.month) {
      getPayrollByMonth({ month: state.month })
        .then((res) => {
          setState({
            startDate: res?.data?.from,
            endDate: res?.data?.to,
          });
        })
        .catch(handleErrorMessage);
    }
  }, [state.month]);

  useEffect(() => {
    const params = { targetId: profile?.id, startDate: state.startDate, endDate: state.endDate };
    if (state.startDate && state.endDate) {
      getRemainMonthlyOvertime(params)
        .then((res) => {
          setState({
            totalHourRemain: res?.data.hourOverTimeRemaining,
          });
        })
        .catch(handleErrorMessage);
    }
  }, [state.startDate, state.endDate]);

  const handleSubmit = async (values: any) => {
    try {
      setState({
        loading: true,
      });
      await createOvertime({
        constructionSiteId: values?.constructionSiteId,
        workingDay: values?.workingDay?.format('YYYY-MM-DD'),
        overtimeType: values?.overtimeType,
        checkIn: values?.checkIn?.format('HH:mm'),
        checkOut: values?.checkOut?.format('HH:mm'),
        hasNight: values?.hasNight ? 1 : 0,
        nightFrom:
          typeof values?.nightFrom === 'string'
            ? values?.nightFrom
            : values?.nightFrom
            ? values?.nightFrom?.format('HH:mm')
            : null,
        nightTo:
          typeof values?.nightTo === 'string'
            ? values?.nightTo
            : values?.nightTo
            ? values?.nightTo?.format('HH:mm')
            : null,
      });
      message.success('成功', 5);
      handleClose();
    } catch (e) {
      handleErrorMessage(e);
    } finally {
      if (isMounted?.current) {
        setState({
          loading: false,
        });
      }
    }
  };

  const [searchConstructionSiteParam, setSearchConstructionSiteParam] = useSetState({
    date: [dateUtils.getDateToString(new Date()), dateUtils.getDateToString(moment(new Date()).subtract(1, 'day'))],
    pageSize: 20,
    keyword: '',
  });
  const { data: listConstructionSite } = useQuery(
    ['list-construction-site-of-leader', searchConstructionSiteParam],
    () => getListConstructionHaveTask(searchConstructionSiteParam).then((res: any) => res?.data),
    {
      enabled: roleId === UserRole.LEADER,
    }
  );

  const handleSearchConstructionSite = useCallback(
    debounce((keyword: string) => setSearchConstructionSiteParam({ keyword }), 300),
    []
  );

  const handleChangeTime = (e: any) => {
    const { workingDay, checkIn, checkOut } = form.getFieldsValue();
    const companyNightFrom = profileCompany?.nightFrom;
    const companyNightTo = profileCompany?.nightTo;

    if (checkIn && checkOut) {
      // const nightFrom = dateUtils.isBeforeTime(checkIn, companyNightFrom) ? companyNightFrom : checkIn;
      // const nightTo = dateUtils.isBeforeTime(checkOut, companyNightTo) ? checkOut : companyNightTo;
      if (dateUtils.isBeforeTime(checkIn, checkOut)) {
        form.setFieldsValue({
          workingDayTo: workingDay,
          // nightFrom,
          // nightTo,
        });
      } else {
        form.setFieldsValue({
          workingDayTo: moment(workingDay).add(1, 'd'),
          // nightFrom,
          // nightTo,
        });
      }

      if (dateUtils.isBeforeTime(checkOut, checkIn)) {
        const nextDay = moment(checkOut).add(1, 'day');
        const duration = moment(nextDay).diff(checkIn);
        setState({
          duration: Math.round(duration / MILLISECOND_A_HOUR),
        });
      } else {
        const duration = moment(checkOut).diff(checkIn);
        setState({
          duration: Math.round(duration / MILLISECOND_A_HOUR),
        });
      }
    }
  };

  return (
    <CommonModal
      width={720}
      handleClose={handleClose}
      isModalVisible
      title="残業打刻"
      footer={null}
      closable
      maskClosable={false}
    >
      <Form
        layout="vertical"
        form={form}
        scrollToFirstError={true}
        onFinish={handleSubmit}
        initialValues={{ workingDay: moment() }}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label="タイプ"
              name="overtimeType"
              rules={[
                {
                  required: true,
                  message: '全ての項目を入力してください。',
                },
              ]}
            >
              <CommonSelect options={ListOvertimeType} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="開始日"
              name="workingDay"
              rules={[
                {
                  required: true,
                  message: '全ての項目を入力してください。',
                },
              ]}
              labelAlign="left"
            >
              <CommonDatepicker
                disabledDate={(date) => {
                  // Lấy ngày 7 ngày trước
                  const sevenDaysAgo = moment().subtract(6, 'days');

                  return date.isAfter(today, 'day') || date.isBefore(sevenDaysAgo, 'day');
                }}
                disabled={false}
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="終了日"
              name="workingDayTo"
              rules={[
                {
                  required: true,
                  message: '全ての項目を入力してください。',
                },
              ]}
              labelAlign="left"
            >
              <CommonDatepicker disabled={true} />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="開始時刻"
              name="checkIn"
              rules={[
                {
                  required: true,
                  message: '全ての項目を入力してください。',
                },
              ]}
              labelAlign="left"
            >
              <CommonDatepicker picker={TYPE_PICKER.TIME} disabled={false} onChange={handleChangeTime} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="終了時刻"
              name="checkOut"
              rules={[
                {
                  required: true,
                  message: '全ての項目を入力してください。',
                },
              ]}
              labelAlign="left"
            >
              <CommonDatepicker picker={TYPE_PICKER.TIME} onChange={handleChangeTime} />
            </Form.Item>
          </Col>

          <AccessControl accessibleRoles={[UserRole.LEADER]}>
            <Col span={24}>
              <Form.Item label="プロジェクト名" name="constructionSiteId" labelAlign="left">
                <CommonSelect
                  filterOption={false}
                  onSearch={handleSearchConstructionSite}
                  optionLabel="name"
                  options={listConstructionSite || []}
                />
              </Form.Item>
            </Col>
          </AccessControl>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const checkIn = getFieldValue('checkIn');
              const checkOut = getFieldValue('checkOut');
              const isDisabled = !!checkIn && !!checkOut;

              return (
                <Col span={24}>
                  <Form.Item label="" valuePropName="checked" name="hasNight">
                    <Checkbox disabled={!state.hasNight || !isDisabled}>深夜</Checkbox>
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const hasNight = getFieldValue('hasNight');
              const checkIn = getFieldValue('checkIn');
              const checkOut = getFieldValue('checkOut');

              const nightFrom = profileCompany?.nightFrom;
              const nightTo = profileCompany?.nightTo;

              return hasNight ? (
                <>
                  <Col span={24}>
                    <Text className="heading-20">深夜労働時間</Text>
                  </Col>

                  <Col xs={12}>
                    <Form.Item
                      label="開始時刻"
                      name="nightFrom"
                      rules={[
                        {
                          required: true,
                          message: '全ての項目を入力してください。',
                        },
                        () => ({
                          validator(rule, value) {
                            if (!value || dateUtils.isBeforeTime(checkIn, value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject('深夜の開始時刻が正しくありません。');
                          },
                        }),
                      ]}
                      labelAlign="left"
                    >
                      <CommonDatepicker picker={TYPE_PICKER.TIME} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label="終了時刻"
                      name="nightTo"
                      rules={[
                        {
                          required: true,
                          message: '全ての項目を入力してください。',
                        },
                        () => ({
                          validator(rule, value) {
                            if (!value || dateUtils.isBeforeTime(value, checkOut)) {
                              return Promise.resolve();
                            }
                            return Promise.reject('深夜の終了時刻が正しくありません。');
                          },
                        }),
                      ]}
                      labelAlign="left"
                    >
                      <CommonDatepicker picker={TYPE_PICKER.TIME} />
                    </Form.Item>
                  </Col>
                </>
              ) : null;
            }}
          </Form.Item>
        </Row>

        <div className="color-red">残業時間: {state.duration}</div>
        <div className="color-red">みなし残業(一ヶ月): {state.totalHourRemain}</div>
        <div className="color-red">
          実際の残業時間: {state.duration - state.totalHourRemain > 0 ? state.duration - state.totalHourRemain : 0}
        </div>

        <Row gutter={10} className="mt-20">
          <Col xs={12}>
            <Button block htmlType="button" className="btn-outline" onClick={handleClose}>
              キャンセル
            </Button>
          </Col>
          <Col xs={12}>
            <Button block htmlType="submit" type="primary" disabled={state?.loading}>
              {'申請 '}
            </Button>
          </Col>
        </Row>
      </Form>
    </CommonModal>
  );
};
