import { Checkbox, message, Spin, UploadProps } from 'antd';
import { uploadMultiple } from 'api/upload';
import icUpload from 'assets/icons/upload.svg';
import icRemove from 'assets/icons/x.svg';
import { ModeType } from 'common';
import { handleErrorMessage } from 'helper';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import icons from 'assets/icons';
import { CommonIconButton } from 'components/CommonIconButton';
import { downloadFileFromUrl, downloadFilesFromUrl } from 'helper/file';

interface IProps extends UploadProps {
  value?: any[];
  onChange?: (file: any) => void;
  maxFile?: number;
  mode?: string;
  allowDownload?: boolean;
  zipFilename?: string;
}

const MAX_FILE = 10;

export const UploadListFile = ({
  value = [],
  onChange,
  maxFile = MAX_FILE,
  mode,
  disabled,
  allowDownload,
  zipFilename,
  ...rest
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  // state for zip file
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isDownload, setIsDownload] = useState(false);
  const isShowListFile = value?.length > 0;

  const handleUpload = async (e: any) => {
    setLoading(true);
    try {
      const files = e.target.files;
      if (files?.length + value?.length <= maxFile) {
        let newListFile: any[] = [];
        const { data, domain } = await uploadMultiple(files);
        newListFile = [...data.map((el: any, i: number) => ({ id: Math.random(), name: el, url: `${domain}/${el}` }))];
        onChange!([...value, ...newListFile]);
      } else {
        message.destroy();
        message.error(`アップロードするファイルは${maxFile}枚以下になります。`, 5);
      }
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const onRemove = (file: any, index: number) => {
    const newValue = [...value.filter((x) => x.id !== file?.id)];

    onChange!(newValue);

    // (document.getElementById('fileUpload') as any).value = null;

    // Fix bug from old code
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    // remove selected id
    if (allowDownload) {
      setSelectedIds((ids) => ids.filter((x) => x !== file.id));
    }
  };

  const handleDownload = async () => {
    if (!selectedIds.length) return;

    const selectedFiles = value
      .filter((file) => selectedIds.includes(file.id))
      .map((file) => ({ url: file.url, fileName: file.name }));

    try {
      setIsDownload(true);

      if (selectedFiles.length === 1) {
        // download  1 file
        await downloadFileFromUrl({ url: selectedFiles[0].url, fileName: zipFilename as string });

        return;
      }

      // download and zip files
      await downloadFilesFromUrl({ files: selectedFiles, zipFilename: zipFilename as string });
    } catch (error) {
    } finally {
      setIsDownload(false);
    }
  };

  return (
    <div>
      <div className={styles.boxUpload}>
        <div className={styles.picker}>
          <label className={styles.wrapperInput}>
            <input
              ref={inputRef}
              id="fileUpload"
              style={{ display: 'none' }}
              type="file"
              accept="application/pdf,image/*"
              multiple
              onChange={(e: any) => handleUpload(e)}
              disabled={disabled}
              {...rest}
            />
            <img src={icUpload} className={styles.boxIcon} alt="" />
          </label>
        </div>
      </div>

      <div className={styles.fileList}>
        {loading && <Spin />}

        {isShowListFile &&
          [...value]?.map((el: any, index) => (
            <div key={el?.id} className={styles.fileItem}>
              {allowDownload && (
                <Checkbox
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      setSelectedIds([...selectedIds, el?.id]);
                    } else {
                      setSelectedIds((ids) => ids.filter((x) => x !== el?.id));
                    }
                  }}
                  checked={selectedIds.includes(el?.id)}
                />
              )}

              <a target="_blank" href={el?.url} rel="noreferrer">
                {el.name}
              </a>
              {mode !== ModeType.VIEW && !disabled && (
                <img src={icRemove} className={styles.icon} onClick={() => onRemove(el, index)} alt="" />
              )}
            </div>
          ))}

        {[allowDownload, isShowListFile].every(Boolean) && (
          <CommonIconButton
            icon={icons.download.gray}
            size="small"
            onClick={handleDownload}
            disabled={isDownload || selectedIds.length === 0}
            loading={isDownload}
          />
        )}
      </div>
    </div>
  );
};
