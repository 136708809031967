import { message } from 'antd';
import {
  CompanyScheduleDay,
  EndOfMonth,
  PaperType,
  ROUND,
  SalaryType,
  SALARY_DELAY_MONTH,
  ShipOfDay,
  STATUS,
  UserRole,
  UserStatus,
  NUMBER_PERCENT_TAX,
  PERCENT_TAX,
  TaxType,
  PaperSystemType,
  TimeOffMode,
  CommonStatus,
  TimeOffType,
  Gender,
  KeySQL,
  CURRENCY_UNIT,
  UserVendorWorkingType,
  ConstructionSiteType,
} from 'common';
import {
  BASE_URL_IMAGE,
  colorsPicker,
  listCompanyBusinessField,
  listDateClosingSalary,
  listDayOffType,
  listGender,
  listModeTimeOff,
  ListMonth,
  listRolesStaff,
  listSalaryType,
  listWeekDisplay,
  MILLISECOND_A_HOUR,
  TYPES_SALARY_FORMAT_TIMES,
} from 'common/const';
import { dateUtils } from 'common/dateUtils';
import {
  IBreakTime,
  ICertificate,
  IComment,
  ICompanyProfile,
  IListSalaryV4,
  IMetadataPrice,
  IPaper,
  IProfile,
  ISchedule,
  ITask,
  ITaskDetail,
  ITaskOfConstructionSite,
  ITimeOffData,
  IVendorWorkerInfo,
  IWorker,
  PayrollV4,
  TShift,
  TypeSalaryItem,
  TypeSalaryItemKey,
} from 'common/interface';
import configs from 'config';
import { History } from 'history';
import Cookies from 'js-cookie';

import _, { cloneDeep, head, isArray, isEmpty, isEqual, isNil, isObject, keyBy, omit, pick, rest } from 'lodash';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import { confirmAlert } from 'react-confirm-alert';
import addressHelper from './address';
import { SHIFT } from 'common/schedule';
import React from 'react';
import { FormInstance } from 'antd/es/form/Form';
import { getFormatDateDataOther } from 'pages/Schedule/utils';
import numeral from 'numeral';

export const handleErrorMessage = (error: any, useCustomMessage?: boolean) => {
  message.destroy();

  if (useCustomMessage) {
    message.error({
      content: error,
      duration: 2,
      style: {
        zIndex: 1001,
      },
    });
  } else {
    message.error({
      content: getErrorMessage(error),
      duration: 2,
      style: {
        zIndex: 1001,
      },
    });
  }

  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.errorMessage || '何らかの問題が発生しました。';
};

export const updateLocationSearchQuery = (history: History, data: any) => {
  let query = queryString.parse(window.location.search);
  query = { ...query, ...data };
  history.push({
    pathname: window.location.pathname,
    search: queryString.stringify(query),
  });
};
export const cleanLocationSearchQuery = (history: History, keys?: string[]) => {
  let query = queryString.parse(window.location.search);
  if (keys) {
    query = omit(query, keys);
  } else {
    query = {};
  }
  history.push({
    pathname: window.location.pathname,
    search: queryString.stringify(query),
  });
};

export const cleanObj = (obj: any) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === 0) {
      delete obj[propName];
    }
  }
  return obj;
};

export const dateSalaryClosing = (day: number | string) => listDateClosingSalary.find((el) => el.id === day)?.name;

export const getDateRange = (salaryClosingDate: number, date: any) => {
  const dayIndex = Number(dateUtils.getDayIndex(date));

  if (salaryClosingDate === EndOfMonth.END_OF_MONTH) {
    return {
      startDate: dateUtils.getStartOfMonth(date).format('YYYY-MM-DD'),
      endDate: dateUtils.getEndOfMonth(date).format('YYYY-MM-DD'),
    };
  } else {
    return {
      startDate:
        dayIndex - salaryClosingDate > 0
          ? dateUtils.getDateOfMonth(salaryClosingDate + 1, date).format('YYYY-MM-DD')
          : dateUtils.getDateOfMonth(salaryClosingDate + 1, dateUtils.getPreviousMonth(date)).format('YYYY-MM-DD'),
      endDate:
        dayIndex - salaryClosingDate > 0
          ? dateUtils.getDateOfMonth(salaryClosingDate, dateUtils.getNextMonth(date)).format('YYYY-MM-DD')
          : dateUtils.getDateOfMonth(salaryClosingDate, date).format('YYYY-MM-DD'),
    };
  }
};

export const getDaysBetweenDates = (startDate: any, endDate: any) => {
  const now = moment(startDate).clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, 'days');
  }
  return dates;
};

export const getSalaryTypeName = (type: number) => {
  switch (type) {
    case SalaryType.CONSTRUCTION_SALARY:
      return 'プロジェクト給';
    case SalaryType.HOUR_SALARY:
      return '時給';
    case SalaryType.MONTH_SALARY:
      return '月給';
    case SalaryType.SHIFT_SALARY:
      return 'プロジェクト給';
    default:
      break;
  }
};

export const getTotalSumOfValueArr = (arrObj: Array<any>, key: any) => {
  return arrObj?.reduce((acc: any, value: any) => acc + (value?.[key] ? Number(value?.[key]) : 0), 0);
};

export const getSumSalary = (arrObj: IListSalaryV4[], key: TypeSalaryItemKey, type: TypeSalaryItem) => {
  const newArr = arrObj.map((el) => ({
    value: renderSalaryItem(el?.payrolls, key, type)?.value,
  }));

  return getTotalSumOfValueArr(newArr, 'value');
};

export const convertListConstructionSiteToCalendar = (constructionSites: any[]) => {
  const events = constructionSites?.map((site) => {
    // TODO Fake start and end time
    const start = moment(`${site.startTime}`).toDate();
    const end = moment(`${site.startTime}`).add(1, 'hour').toDate();
    return {
      ...site,
      start,
      end,
      title: getConstructionSiteRecap(site),
    };
  });
  return events;
};

export const getConstructionSiteRecap = (constructionSite: any) => {
  const roleId = Number(Cookies.get('roleId'));
  const adminRoles = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER, UserRole.CUSTOMER, UserRole.LEADER];
  const { kenId, cityId } = constructionSite;
  const name = constructionSite?.constructionName || constructionSite?.name;
  const customerCompanyName = constructionSite?.customerCompanyName || '';
  const customerName = constructionSite?.customerName || '';
  const startTime = constructionSite?.startTime ? moment(constructionSite?.startTime).format('YYYY/MM/DD') : 'N/A';
  const kenName = addressHelper.kens[kenId]?.name || 'N/A';
  const cityName = addressHelper.cities[cityId]?.name || 'N/A';

  const adminRecap = [name, customerName, customerCompanyName].filter(Boolean).join(' - ');
  return adminRoles.includes(roleId) ? adminRecap : name;
};

export const getNewConstructionSiteRecap = (constructionSiteName: any, customerName: any, customerCompany: any) => {
  return [constructionSiteName || 'N/A', customerName || 'N/A', customerCompany || 'N/A'].join(' - ');
};

export const getTimeDesire = (task: ITask) => {
  const isHaveTimeMorning = [task?.startTimeDesire, task?.endTimeDesire].some((item) => !isNil(item));
  const isHaveTimeAfternoon = [task.startTimeAfternoonDesire, task.endTimeAfternoonDesire].some((item) => !isNil(item));
  const isHaveTimeEvening = [task.startTimeEveningDesire, task.endTimeEveningDesire].some((item) => !isNil(item));
  const isHaveTimeOther = [task.startTimeOtherDesire, task.endTimeOtherDesire].some((item) => !isNil(item));

  const isShowTimeDesire = [isHaveTimeMorning, isHaveTimeAfternoon, isHaveTimeEvening, isHaveTimeOther].some(
    (item) => item
  );

  if (!isShowTimeDesire) return '';

  if (isHaveTimeMorning) return `${task.startTimeDesire ?? ''} - ${task.endTimeDesire ?? ''}`;
  if (isHaveTimeAfternoon) return `${task.startTimeAfternoonDesire ?? ''} - ${task.endTimeAfternoonDesire ?? ''}`;
  if (isHaveTimeEvening) return `${task.startTimeEveningDesire ?? ''} - ${task.endTimeEveningDesire ?? ''}`;
  if (isHaveTimeOther) return `${task.startTimeOtherDesire ?? ''} - ${task.endTimeOtherDesire ?? ''}`;

  return '';
};

interface IConfirmPopup {
  title?: string;
  message?: string | any;
  okText?: string;
  cancelText?: string;
  onOk?: () => any;
  onCancel?: () => any;
  childrenElement?: () => React.ReactNode;
  customUI?: (customUiOptions: { title: string; message: string; onClose: () => void }) => React.ReactNode;
  classNameButtonCancel?: string;
  classNameButtonOk?: string;
}

export const confirmPopup = ({
  title,
  message,
  okText = 'はい',
  cancelText = 'いいえ',
  onOk = () => {},
  onCancel = () => {},
  childrenElement,
  customUI,
  classNameButtonCancel,
  classNameButtonOk,
}: IConfirmPopup) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: cancelText,
        onClick: onCancel,
        className: classNameButtonCancel,
      },
      {
        label: okText,
        onClick: onOk,
        className: classNameButtonOk,
      },
    ],
    childrenElement,
    customUI,
  });
};

export const confirmPopupAsync = async ({ title, ...rest }: IConfirmPopup): Promise<boolean> => {
  return new Promise((resolve) => {
    confirmPopup({
      title,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      ...rest,
    });
  });
};

export const getDayOfWeekSalary = (weekDay: number) => {
  switch (weekDay) {
    case CompanyScheduleDay.SUNDAY:
      return '日';
    case CompanyScheduleDay.MONDAY:
      return '月';
    case CompanyScheduleDay.TUESDAY:
      return '火';
    case CompanyScheduleDay.WEDNESDAY:
      return '水';
    case CompanyScheduleDay.THURSDAY:
      return '木';
    case CompanyScheduleDay.FRIDAY:
      return '金';
    case CompanyScheduleDay.SATURDAY:
      return '土';
    default:
      break;
  }
};

interface IList {
  id: number;
  name: string;
}
export const getTitleFromList = (list: IList[], value: number | undefined) => {
  const objectTitle: IList | undefined = list.find((element: IList) => element.id === value);
  return objectTitle?.name || ' ';
};

export const getResourceName = (id: number, listResource: any[]) => {
  return listResource?.find((el: any) => el?.id == id)?.name;
};

export const convertCardNumberToStar = (cardNumber: any) => {
  if (cardNumber) {
    let cardStr = cardNumber.toString();
    let newCard = '';
    for (let i = 0; i < cardStr.length; i++) {
      if (i < cardStr.length - 4) newCard += '*';
      else newCard += cardStr[i];
    }
    return newCard;
  }
};

export const standardFile = (file: string) => {
  if (file && file.includes(BASE_URL_IMAGE)) {
    const arrValue = file.split('/').reverse();
    return arrValue[0];
  }
};

export const getSalaryType = (id: number) => {
  return listSalaryType.find((el: any) => el.id === id)?.name;
};

export const getRoleName = (roleId: number) => {
  switch (roleId) {
    case UserRole.SUPER_ADMIN:
      return 'スーパーアドミン';
    case UserRole.ADMIN:
      return 'アドミン';
    case UserRole.MANAGER:
      return 'マネージャー';
    case UserRole.OFFICE_MANAGER:
      return 'オフィスマネージャー';
    case UserRole.LEADER:
      return 'リーダー';
    case UserRole.VENDOR_MANAGER:
      return 'サプライヤーマネージャー';
    case UserRole.VENDOR:
      return 'サプライヤー';
    case UserRole.CUSTOMER:
      return 'クライアント';
    case UserRole.VENDOR_WORKER:
      return 'サプライヤーのスタッフ';
    case UserRole.PART_TIME_WORKER:
      return 'アルバイト';
    case UserRole.OTHER_WORKER:
      return 'フリーランス';
    case UserRole.WORKER:
      return '社員';
  }
};
export const getFileNameFromUrl = (url: string) => {
  if (!url) return undefined;
  return _.last(url.split('/'));
};

export const newListFile = (listFile: Array<any>) => {
  return listFile.map((el: any) => {
    return {
      url: el.url,
      name: el.name,
    };
  });
};

export const getWorkerType = (roleId: number | undefined) => {
  switch (roleId) {
    case UserRole.PART_TIME_WORKER:
      return 'アルバイト';
    case UserRole.OTHER_WORKER:
      return 'フリーランス';
    case UserRole.WORKER:
      return '社員';
    case UserRole.ADMIN:
      return 'アドミン';
    case UserRole.MANAGER:
      return 'マネージャー';
    case UserRole.OFFICE_MANAGER:
      return 'オフィスマネージャー';
    case UserRole.LEADER:
      return 'リーダー';
    default:
      return null;
  }
};

export const navigateToApp = (token: string, refreshToken: string, companyId?: string, roleId?: string) => {
  const exp = { token, refreshToken, companyId, roleId };
  const linkToApp = `shokuninapp://path?${JSON.stringify(exp)}`;
  window.location.href = linkToApp;
};

export const canBlockStaff = (userRoleId: number, staffRole: any) => {
  const ROLES_CAN_BLOCK = [UserRole.ADMIN, UserRole.MANAGER];

  if (!ROLES_CAN_BLOCK.includes(userRoleId)) return false;

  if (userRoleId === UserRole.ADMIN) {
    return staffRole !== UserRole.ADMIN;
  }

  if (userRoleId === UserRole.MANAGER) {
    return !ROLES_CAN_BLOCK.includes(staffRole) && staffRole !== UserRole.OFFICE_MANAGER;
  }

  return false;
};

export const canInactiveCustomer = (userRoleId: number) => {
  const ROLES_CAN_INACTIVE = [UserRole.ADMIN, UserRole.MANAGER];

  if (!ROLES_CAN_INACTIVE.includes(userRoleId)) return false;

  return true;
};

export const canBlockVendor = (userRoleId: number) => {
  const ROLES_CAN_BLOCK = [UserRole.ADMIN, UserRole.MANAGER];

  if (!ROLES_CAN_BLOCK.includes(userRoleId)) return false;

  return true;
};

export const getDisabledEditStaff = (
  userRoleId: number,
  staffRole: any,
  staffStatus: any,
  userId: any,
  staffId: any,
  isAdminOriginal?: boolean
) => {
  const EDIT_STAFF_PERMISSION = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER];
  const isRoleEditable = EDIT_STAFF_PERMISSION.includes(userRoleId);

  if (userRoleId === UserRole.ADMIN && staffRole === UserRole.ADMIN && userId !== staffId && !isAdminOriginal)
    return true;

  return (
    // user is blocked cannot edited, vendor can edit worker || only admin can edit detail admin
    staffStatus === UserStatus.BLOCKED ||
    (isRoleEditable ? (UserRole.ADMIN === userRoleId ? false : staffRole === UserRole.ADMIN ? true : false) : false) ||
    staffRole === UserRole.VENDOR_WORKER
  );
};

export const getMoneyConvertJP = (value: any) => {
  try {
    const money = value || 0;
    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(money);
  } catch (error) {
    return '0';
  }
};

export const formatCurrencyJP = (value?: number | null, prefix = CURRENCY_UNIT.YEN) => {
  try {
    const money = value || 0;

    return prefix + new Intl.NumberFormat('en-US').format(money);
  } catch (error) {
    return '0';
  }
};

export const formatCurrencyJPDefaultEmpty = (value?: number, prefix = '¥') => {
  if (isNil(value)) return '';

  try {
    const money = value || 0;

    return prefix + new Intl.NumberFormat('en-US').format(money);
  } catch (error) {
    return '';
  }
};

export const formatNumberWithComma = (value?: number) => {
  // Number regex
  if (isNil(value)) return;

  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const getPaperTypeName = (type: number, paper: IPaper) => {
  const isPaperCreateByPaper = paper?.sender?.company?.roleId === UserRole.OTHER_WORKER;

  if (isPaperCreateByPaper) {
    return '請求書';
  }

  if (type === PaperType.PAYMENT_RECEIPT) {
    return '支払証明書';
  }

  if (type === PaperType.ESTIMATION) {
    return '見積書';
  }

  if (type === PaperType.INVOICE) {
    return '領収書';
  }

  if (
    type === PaperType.ACCEPTANCE_CONSTRUCTION_SITE_FIXED_PRICE ||
    type === PaperType.ACCEPTANCE_CONSTRUCTION_SITE_LABOR
  ) {
    return '納品書';
  }

  if (type === PaperType.CREATE_PAYMENT_REQUEST_FOR_OTHER_WORKER) {
    return '代理請求';
  }

  if (type === PaperType.PAYMENT_REQUEST_FIXED_PRICE || type === PaperType.PAYMENT_REQUEST_LABOR) {
    return '請求書 ';
  }
};

export const getDatePaperPdf = (paper: IPaper) => {
  // Type: 1,2,5,6, 7 -> stageStartFrom - stageStartTo
  if (
    [
      PaperType.PAYMENT_REQUEST_LABOR,
      PaperType.PAYMENT_REQUEST_FIXED_PRICE,
      PaperType.ACCEPTANCE_CONSTRUCTION_SITE_LABOR,
      PaperType.ACCEPTANCE_CONSTRUCTION_SITE_FIXED_PRICE,
      PaperType.CREATE_PAYMENT_REQUEST_FOR_OTHER_WORKER,
    ].includes(paper?.type)
  ) {
    const stageStartFrom = paper?.stageStartFrom ? moment(paper?.stageStartFrom).format('YYYYMMDD') : '';
    const stageStartTo = paper?.stageStartTo ? moment(paper?.stageStartTo).format('YYYYMMDD') : '';

    return `${stageStartFrom}-${stageStartTo}`;
  }

  // 3, 4, 8 -> createdAt
  return paper?.createdAt ? moment(paper?.createdAt).format('YYYYMMDD') : '';
};

export const sortArrByDate = (arr: any, key: any) => {
  return arr.sort((a: any, b: any): any => (new Date(a?.[key]) as any) - (new Date(b?.[key]) as any));
};

export const renderBreakTime = (roleId: number, breakTimeObj: IBreakTime[]) => {
  const listBreakTime = breakTimeObj.map((el: IBreakTime) => ({
    roleId,
    hour: Number(el.hour),
    break: Number(el.break),
    minute: Number(el.minute),
  }));
  return listBreakTime;
};

export const breakTimeCompany = (values: any) => {
  return [
    ...renderBreakTime(UserRole.ADMIN, values.admin),
    ...renderBreakTime(UserRole.MANAGER, values.manager),
    ...renderBreakTime(UserRole.OFFICE_MANAGER, values.officeManager),
    ...renderBreakTime(UserRole.LEADER, values.leader),
    ...renderBreakTime(UserRole.WORKER, values.worker),
    ...renderBreakTime(UserRole.OTHER_WORKER, values.otherWorker),
    ...renderBreakTime(UserRole.PART_TIME_WORKER, values.partTimeWorker),
  ];
};

export const renderFormBreakTime = (breakTimes: IBreakTime[]) => {
  const admin = breakTimes?.filter((el: IBreakTime) => el?.roleId === UserRole.ADMIN);
  const manager = breakTimes?.filter((el: IBreakTime) => el?.roleId === UserRole.MANAGER);
  const officeManager = breakTimes?.filter((el: IBreakTime) => el?.roleId === UserRole.OFFICE_MANAGER);
  const leader = breakTimes?.filter((el: IBreakTime) => el?.roleId === UserRole.LEADER);
  const worker = breakTimes?.filter((el: IBreakTime) => el?.roleId === UserRole.WORKER);
  const otherWorker = breakTimes?.filter((el: IBreakTime) => el?.roleId === UserRole.OTHER_WORKER);
  const partTimeWorker = breakTimes?.filter((el: IBreakTime) => el?.roleId === UserRole.PART_TIME_WORKER);

  return { admin, manager, officeManager, leader, worker, otherWorker, partTimeWorker };
};

export const getFutureDateHoliday = (dayOfWeek: number = 0) => {
  let date;
  const todayWeekDay = dateUtils.getWeekday();

  if (dayOfWeek > todayWeekDay) {
    date = moment().add(dayOfWeek - todayWeekDay, 'day');
  }
  if (dayOfWeek <= todayWeekDay) {
    date = moment().add(dayOfWeek - todayWeekDay + 7, 'day');
  }
  return date;
};

export const renderEffectiveFromDate = (key: string) => {
  if (key === SALARY_DELAY_MONTH.NOW) {
    return 0;
  }
  if (key === SALARY_DELAY_MONTH.NEXT_MONTH) {
    return 1;
  }
  if (key === SALARY_DELAY_MONTH.TWO_MONTH_LATER) {
    return 2;
  }
};

export const getUserConfig = (monthNumber: string) => {
  const monthString = `${monthNumber}ヶ月`;
  return [
    `${monthNumber}ヶ月`,
    `${Number(monthNumber) + 12}ヶ月`,
    `${Number(monthNumber) + 24}ヶ月`,
    `${Number(monthNumber) + 36}ヶ月`,
    `${Number(monthNumber) + 48}ヶ月`,
    `${Number(monthNumber) + 60}ヶ月`,
    `${Number(monthNumber) + 72}ヶ月+`,
  ];
};

export const getUserProbationStatus = (isProbation: number) => {
  if (isProbation) {
    return '研修';
  } else {
    return '正社員';
  }
};

export const getWorkingDaySalaryShift = (schedule: any) => {
  const listWorkDay = [] as any;
  listWeekDisplay?.forEach((el: any) => {
    if (schedule?.[el.name] === STATUS.ACTIVE) {
      listWorkDay?.push(el.label);
    }
  });
  const workDayString = listWorkDay?.join('、');
  return workDayString;
};

export const getWorkingDaySalaryHour = (schedule: any) => {
  const listWorkDay = [] as any;
  listWeekDisplay?.forEach((el: any) => {
    if (schedule?.[el.name] === STATUS.ACTIVE) {
      listWorkDay?.push(`${el.label} (${schedule?.[el.workHour]}時間)`);
    }
  });
  const workDayString = listWorkDay?.join('、');
  return workDayString;
};

export const renderCheckInCheckOutDate = (checkIn: string, checkOut: string, workingDay: string) => {
  if (dateUtils.isBeforeTime(checkIn, checkOut) || !checkOut) return moment(workingDay).format('YYYY/MM/DD');
  return moment(workingDay).add(1, 'day').format('YYYY/MM/DD');
};

export const flattenArrOfArr = (arr: any[]) => arr?.reduce((prev: any, cur) => prev?.concat(cur));

export const roundTwoDigitAfterDecimal = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

export const getDurationOvertime = (checkIn: any, checkOut: any) => {
  let duration = 0;
  if (dateUtils.isBeforeTime(checkOut, checkIn)) {
    const nextDay = moment(checkOut).add(1, 'day');
    const timeDiff = moment(nextDay).diff(checkIn);
    duration = Math.round(timeDiff / MILLISECOND_A_HOUR);
  } else {
    const timeDiff = moment(checkOut).diff(checkIn);
    duration = Math.round(timeDiff / MILLISECOND_A_HOUR);
  }
  return duration;
};

export const getDurationString = (checkIn: string, checkOut: string) => {
  let duration = 0;

  if (dateUtils.isBeforeTime(checkOut, checkIn)) {
    const nextDay = moment(checkOut).add(1, 'day');
    const timeDiff = moment(nextDay).diff(checkIn);
    duration = Math.round(timeDiff / MILLISECOND_A_HOUR);
  } else {
    const timeDiff = moment(checkOut).diff(checkIn);
    duration = Math.round(timeDiff / MILLISECOND_A_HOUR);
  }
  return duration;
};

export const calcDuration = (checkIn: string, checkOut: string) => {
  try {
    if (checkIn === checkOut) return 0;
    const checkInObj = moment(checkIn, 'HH:mm');
    const checkOutObj = checkIn > checkOut ? moment(checkOut, 'HH:mm').add(1, 'day') : moment(checkOut, 'HH:mm');
    return Number(checkOutObj.diff(checkInObj, 'hour', true).toFixed(2));
  } catch (error) {
    return 0;
  }
};

export const getNumeratorOfString = (text: string) => {
  return Number(text.split('/')[0]);
};

export const isExpiredPayment = (expiredAt: string | null, createdAt: string) => {
  const expiredDate = expiredAt ?? moment(createdAt).add(30, 'day');
  return moment(expiredDate).isBefore(moment());
};

export const groupTaskIdPaper = (tableTask: any[]) => {
  if (isEmpty(tableTask)) return [];
  const mapObj = tableTask.map((el) => ({
    ...el,
    shift: [el.shift],
  }));

  const arrayHashmap = mapObj.reduce((obj, item) => {
    obj[item.id] ? obj[item.id].shift.push(...item.shift) : (obj[item.id] = { ...item });
    return obj;
  }, {});

  const newDataGroup = Object.values(arrayHashmap).map((el: any) => ({
    taskId: el.id,
    shifts: {
      morningShift: el.shift.includes(ShipOfDay.MORNING),
      afternoonShift: el.shift.includes(ShipOfDay.AFTERNOON),
      eveningShift: el.shift.includes(ShipOfDay.EVENING),
      otherShift: el.shift.includes(ShipOfDay.OTHER),
    },
  }));

  return newDataGroup;
};

const listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const getSimpleMonthTax = (month: number, taxMonth: number, taxOtherMonth: number) => {
  return listMonth.map((el) => ({
    index: el,
    month: el,
    value: el === month ? taxMonth : taxOtherMonth,
  }));
};

export const getFullMonthTax = (metadataObj: any) => {
  return ListMonth.map((el) => ({
    index: el.id,
    month: el.id,
    value: metadataObj[`${el.id}`],
  }));
};

export const convertMonthTaxToForm = (data: any[]) => {
  if (data) {
    return data?.reduce((a, v) => ({ ...a, [`${v.month}`]: v.value }), {});
  }
  return { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 };
};

export const convertPeople = (people: number) => {
  if (Number(people) > 0) {
    return people;
  }
  return 0;
};

export const convertDateTime = (date: string) => {
  return date.replace('-', '/');
};

export const sumPeopleMissing = (task: ITaskOfConstructionSite) => {
  const peopleMissing =
    convertPeople(task?.newDataMissingSlot?.morning) +
    convertPeople(task?.newDataMissingSlot?.afternoon) +
    convertPeople(task?.newDataMissingSlot?.evening) +
    convertPeople(task?.newDataMissingSlot?.other);

  return convertPeople(peopleMissing);
};

export const sumPeopleMissTask = (task: any) => {
  // const maxWorkerMissing = _.sumBy(task, 'maxWorkerMissing');
  // const maxWorkerEveningMissing = _.sumBy(task, 'maxWorkerEveningMissing');
  // const maxWorkerOtherMissing = _.sumBy(task, 'maxWorkerOtherMissing');

  // return convertPeople(maxWorkerMissing + maxWorkerEveningMissing + maxWorkerOtherMissing);

  const totalMissingPeople = (task as ITaskOfConstructionSite[])?.reduce((sum, t) => {
    return sum + sumPeopleMissing(t);
  }, 0);

  return convertPeople(totalMissingPeople);
};

export const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const formatDate = 'YYYY-MM-DD';

export const renderSalaryValue = (salaryPrice: PayrollV4 | undefined | null) => {
  if (!salaryPrice) return null;

  if (TYPES_SALARY_FORMAT_TIMES.includes(salaryPrice?.type)) {
    return dateUtils.convertHoursToTime(salaryPrice?.value);
  }

  return salaryPrice?.valueCustom ? getToFixedNumber(salaryPrice?.valueCustom) : getToFixedNumber(salaryPrice?.value);
};

export const renderSalaryItem = (salaryArr: PayrollV4[] | undefined, key: TypeSalaryItemKey, type: TypeSalaryItem) => {
  if (!salaryArr || !salaryArr.length) return null;
  return salaryArr.find((el) => el.key === key && el.type === type);
};

export const getExportedSalaryData = (salaryArr: any, userIds: number[]) => {
  if (!salaryArr) return;
  const arrayValues = Object.values(salaryArr).flat();

  const userArrs = userIds.map((el) => ({
    id: el,
    user: arrayValues.find((values: any) => values.id === el),
    data: arrayValues.filter((values: any) => values.id === el),
  }));

  return userArrs;
};

export const formatValueSalary = (type: string | undefined, value: string | null | undefined) => {
  if (!type || value === null || value === undefined) return '';

  if (
    [
      'TOTAL_STANDARD_WORKING_DAY',
      'TOTAL_STANDARD_WORKING_HOUR',
      'TOTAL_ACTUAL_WORKING_DAY',
      'TOTAL_ACTUAL_WORKING_HOUR',
      'TOTAL_MORNING_SHIFT',
      'TOTAL_AFTERNOON_SHIFT',
      'TOTAL_EVENING_SHIFT',
      'TOTAL_EVENING_SHIFT',
      'TOTAL_ACTUAL_WORKING_SHIFT',
      'TOTAL_HOUR_DAY_OFF_FULL_SALARY',
      'TOTAL_DAY_OFF_NO_SALARY',
      'TOTAL_MINUTE_LATE_OR_EARLY',
      'TOTAL_HOUR_OT_COMMON',
      'TOTAL_HOUR_OT_HOLIDAY',
      'TOTAL_HOUR_OT_WEEKEND',
      'TOTAL_HOUR_OT_NIGHT_SHIFT',
      'TOTAL_HOLIDAY',
    ].includes(type)
  ) {
    return `${value}時間`;
  }

  if (
    [
      'TIME_SHEET_CUSTOMIZE',
      'AVG_ONE_HOUR_SALARY',
      'TOTAL_BASIC_SALARY',
      'OVERTIME_SALARY_COMMON',
      'OVERTIME_SALARY_WEEKEND',
      'OVERTIME_SALARY_HOLIDAY',
      'OVERTIME_SALARY_NIGHT_SHIFT',
      'TOTAL_SALARY_DAY_OFF',
      'TOTAL_SUBTRACT_SALARY_EARLY_OR_LATE',
      'TOTAL_SALARY_BEFORE_TAX',
      'TOTAL_TAX_AND_INSURANCE',
      'HEAL_INSURANCE',
      'PERSONAL_INSURANCE',
      'UNEMPLOYMENT_INSURANCE',
      'PERSONAL_INCOME_TAX',
      'RESIDENT_TAX',
      'TOTAL_SALARY',
      'SALARY_AND_SUBSIDIZE_CUSTOMIZE',
      'TAX_AND_INSURANCE_CUSTOMIZE',
      'BASIC_SALARY',
      'TOTAL_SALARY_FROM_TIMEKEEPING',
      'TOTAL_HARD_WORK_SUBSIDY',
      'TOTAL_COMPANY_SUBSIDY',
      'TOTAL_COST_REQUEST',
    ].includes(type)
  ) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '円';
  }

  return value;
};

export const parseNumber = (value: any) => {
  return Number.parseFloat(value).toFixed(2);
};

export const roundTax = (value: number) => {
  return Math.floor(value / ROUND.TEN);
};

export const parserNumber = (val: any) => {
  const negativeNumber = /^-/;
  const testNegativeNumber = negativeNumber.test(val);
  if (!val) return '';
  if (testNegativeNumber) return val;
  return Number(val.replace(/\D/g, ''));
};

export const getListMonthBetweenDate = (startDate: string | Date | undefined, endDate: string | Date | undefined) => {
  const start = moment(startDate);
  const end = moment(endDate);

  let betweenMonths: string[] = [];

  if (start < end) {
    const date = start.startOf('month');
    while (date < end.endOf('month')) {
      betweenMonths.push(date.format('YYYY-MM'));
      date.add(1, 'month');
    }
  }

  return betweenMonths;
};

export const getListMonthOfYear = (year: string) => Array.from(Array(12).keys()).map((el) => `${year}-${el + 1}`);

export const getToFixedNumber = (value: any) => Number(Number(value).toFixed(2));

export const renderBloodPressure = (value: string) => {
  const bloodPressure = Number(value);
  if (bloodPressure) {
    return `${bloodPressure} mmHg`;
  }
  return '';
};

export const removeQueryParam = (paramName: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(paramName);
  const newUrl = url.toString();

  window.history.replaceState(null, '', newUrl);
};

export const isVendorCustomer = (profile?: IProfile) => {
  return profile?.company?.roleId === UserRole.VENDOR && Boolean(profile?.company?.isCustomer);
};

export const isVendor = (profile?: IProfile) => {
  return profile?.company?.roleId === UserRole.VENDOR && !profile?.company?.isCustomer;
};

export const getTimeFromHourMinutes = (hours?: string, minutes?: string) => {
  return `${hours || '00'}:${minutes || '00'}`;
};

export const getScheduleTime = (schedule?: ISchedule) => {
  if (!schedule) return;

  let resultArr = [];

  if (schedule.monday) {
    resultArr.push(`月 (${schedule.mondayHour || '00'}:${schedule.mondayMinute || '00'}時間)`);
  }
  if (schedule.tuesday) {
    resultArr.push(`火 (${schedule.tuesdayHour || '00'}:${schedule.tuesdayMinute || '00'}時間)`);
  }
  if (schedule.wednesday) {
    resultArr.push(`水 (${schedule.wednesdayHour || '00'}:${schedule.wednesdayMinute || '00'}時間)`);
  }
  if (schedule.thursday) {
    resultArr.push(`木 (${schedule.thursdayHour || '00'}:${schedule.thursdayMinute || '00'}時間)`);
  }
  if (schedule.friday) {
    resultArr.push(`金 (${schedule.fridayHour || '00'}:${schedule.fridayMinute || '00'}時間)`);
  }
  if (schedule.saturday) {
    resultArr.push(`土 (${schedule.saturdayHour || '00'}:${schedule.saturdayMinute || '00'}時間)`);
  }
  if (schedule.sunday) {
    resultArr.push(`日 (${schedule.sundayHour || '00'}:${schedule.sundayMinute || '00'}時間)`);
  }

  return resultArr.join(', ');
};

export const getSCheduleDay = (schedule?: ISchedule) => {
  if (!schedule) return;

  let resultArr = [];

  if (schedule.monday) {
    resultArr.push(`月`);
  }
  if (schedule.tuesday) {
    resultArr.push(`火`);
  }
  if (schedule.wednesday) {
    resultArr.push(`水`);
  }
  if (schedule.thursday) {
    resultArr.push(`木`);
  }
  if (schedule.friday) {
    resultArr.push(`金`);
  }
  if (schedule.saturday) {
    resultArr.push(`土`);
  }
  if (schedule.sunday) {
    resultArr.push(`日`);
  }

  return resultArr.join(', ');
};

export const getCurrentShift = (shift: number) => {
  if (shift === SHIFT.MORNING) {
    return 'morningShift';
  } else if (shift === SHIFT.AFTERNOON) {
    return 'afternoonShift';
  } else if (shift === SHIFT.EVENING) {
    return 'eveningShift';
  } else {
    return 'otherShift';
  }
};

export function getValueFromNestedObject(obj: Record<string, any>, keysArray: string | string[]): any {
  if (!obj || !keysArray || !Array.isArray(keysArray) || keysArray.length === 0) {
    return undefined;
  }

  const [currentKey, ...remainingKeys] = keysArray;

  if (remainingKeys.length === 0) {
    return obj?.[currentKey];
  }

  return getValueFromNestedObject(obj[currentKey], remainingKeys);
}

export const getSessionInPage = ({ namePage, nameField }: { namePage: string; nameField: string | string[] }) => {
  const sessionString = sessionStorage.getItem(namePage);

  const sessionObj = sessionString ? JSON.parse(sessionString) : {};

  if (Array.isArray(nameField)) {
    return getValueFromNestedObject(sessionObj, nameField);
  }

  return sessionObj?.[nameField];
};

export const setSessionInPage = ({ namePage, value }: { namePage: string; value: Record<string, any> }) => {
  const sessionString = sessionStorage.getItem(namePage);
  const sessionObj = sessionString ? JSON.parse(sessionString) : {};

  sessionStorage.setItem(namePage, JSON.stringify({ ...sessionObj, ...value }));
};

export const getShiftTitle = (obj: IComment) => {
  const arrShiftTitle: string[] = [];

  if (obj.isMorningShift) {
    arrShiftTitle.push('午前');
  }
  if (obj.isAfternoonShift) {
    arrShiftTitle.push('午後');
  }
  if (obj.isEveningShift) {
    arrShiftTitle.push('夜間');
  }
  if (obj.isOtherShift) {
    arrShiftTitle.push('オプション');
  }

  return arrShiftTitle.join(', ');
};

export const getTitleShiftTask = (task: ITask) => {
  const arrShiftTitle: string[] = [];

  if (task?.morningShift) {
    arrShiftTitle.push('午前');
  }
  if (task?.afternoonShift) {
    arrShiftTitle.push('午後');
  }
  if (task?.eveningShift) {
    arrShiftTitle.push('夜間');
  }
  if (task?.otherShift) {
    arrShiftTitle.push('オプション');
  }

  return arrShiftTitle.join(', ');
};

export const getNameShiftFromTShift = (shift?: TShift) => {
  switch (shift) {
    case 'morningShift':
      return '午前';
    case 'afternoonShift':
      return '午後';
    case 'eveningShift':
      return '夜間';
    case 'otherShift':
      return 'オプション';
    default:
      return '';
  }
};

export const getShiftAndExtraWorker = (obj: IComment) => {
  const arrShiftTitle: string[] = [];

  if (obj.isMorningShift) {
    arrShiftTitle.push(`午前: ${obj?.morningExtraWorker || 0} `);
  }
  if (obj.isAfternoonShift) {
    arrShiftTitle.push(`午後: ${obj.afternoonExtraWorker || 0}`);
  }
  if (obj.isEveningShift) {
    arrShiftTitle.push(`夜間: ${obj.eveningExtraWorker || 0}`);
  }
  if (obj.isOtherShift) {
    arrShiftTitle.push(`オプション:${obj.otherExtraWorker || 0}`);
  }

  return arrShiftTitle.join(', ');
};

const isCustomerMetadata = (event: ITask) => {
  return event?.metadataCustomerUpdate ? true : false;
};
const getEmptyTask = (event: ITask) => {
  const isFulledShift = event?.startTime || event?.endTime;
  return isFulledShift;
};
const isCustomer = (event: ITask) => event?.creatorRoleId === UserRole.CUSTOMER || event?.isCustomer;
const isAdminUpdate = (event: ITask) => {
  return event?.isAdminUpdate ? true : false;
};

// Customer || Vendor_customer create task
export const isRedColorCalender = (event: ITask) => {
  return !isCustomerMetadata(event) && !getEmptyTask(event);
};

// is added metadata
export const isOrangeCalendar = (event: ITask) => {
  return isCustomerMetadata(event);
};

// is admin updated
export const isPurpleCalendar = (event: ITask) => {
  return isCustomer(event) && getEmptyTask(event) && isAdminUpdate(event) && !isCustomerMetadata(event);
};

export const getColorOfCalendar = (event: ITask) => {
  const isRedColor = isRedColorCalender(event); // Customer tạo task mới. admin chưa vào điền timeFrom timeTo
  const isOrangeColor = isOrangeCalendar(event); // Đang chứa metadata
  const isPurpleColor = isPurpleCalendar(event); // task customer tạo, admin đã vào update timeFrom- timeTo
  const isBlackColor = !isRedColor && !isOrangeColor && !isPurpleColor; // task Admin create

  // check if task is assigned for worker or vendor
  const isTaskAssigned = [event?.countVendor, event?.workers?.length].some((item) => item > 0);

  // gray color : task is assigned
  const bgGrayColor = isTaskAssigned;

  return {
    isRedColor,
    isOrangeColor,
    isPurpleColor,
    isBlackColor,
    bgGrayColor,
  };
};

const splitTimeRange = (timeStart: Moment, timeEnd: Moment) => {
  const ranges: { start: Moment; end: Moment }[] = [
    {
      start: timeStart,
      end: timeStart.clone().set({ hour: 23, minute: 59 }),
    },
    {
      start: timeEnd.clone().set({ hour: 0, minute: 0 }),
      end: timeEnd,
    },
  ];

  return ranges;
};

const compareTimes = (timeStart: Moment, timeEnd: Moment) => {
  const startHour = timeStart.hour();
  const startMinute = timeStart.minute();

  const endHour = timeEnd.hour();
  const endMinute = timeEnd.minute();

  if (endHour < startHour) {
    return true;
  } else if (endHour === startHour) {
    return endMinute < startMinute;
  }

  return false;
};

export const checkOverlapTwoTime = (timeStart1: Moment, timeEnd1: Moment, timeStart2: Moment, timeEnd2: Moment) => {
  const overlap =
    (timeStart1.isBefore(timeEnd2) && timeEnd1.isAfter(timeStart2)) ||
    timeStart1.isSame(timeStart2) ||
    timeStart1.isSame(timeEnd2) ||
    timeEnd1.isSame(timeStart2) ||
    timeEnd1.isSame(timeEnd2) ||
    (timeStart2.isBefore(timeEnd1) && timeEnd2.isAfter(timeStart1));

  return overlap;
};

export const checkOverlapTimesShift = (timeStart1: Moment, timeEnd1: Moment, timeStart2: Moment, timeEnd2: Moment) => {
  const overlap =
    (timeStart1.isBefore(timeEnd2) && timeEnd1.isAfter(timeStart2)) ||
    timeStart1.isSame(timeStart2) ||
    // timeStart1.isSame(timeEnd2) ||
    // timeEnd1.isSame(timeStart2) ||
    timeEnd1.isSame(timeEnd2) ||
    (timeStart2.isBefore(timeEnd1) && timeEnd2.isAfter(timeStart1));

  return overlap;
};

export const checkOverlapTimeShift = (
  timeSelectStart: Moment,
  timeSelectEnd: Moment,
  timeShiftStart: Moment,
  timeShiftEnd: Moment
) => {
  const isShiftExtendToTomorrow = compareTimes(timeShiftStart, timeShiftEnd);

  if (isShiftExtendToTomorrow) {
    const rangesTime = splitTimeRange(timeShiftStart, timeShiftEnd);

    const arrCheckOverlapTime = rangesTime.map(({ start, end }) => {
      return checkOverlapTimesShift(timeSelectStart, timeSelectEnd, start, end);
    });

    return arrCheckOverlapTime.some((overlap) => overlap);
  }

  // shift === 'afternoon' | morning
  return checkOverlapTimesShift(timeSelectStart, timeSelectEnd, timeShiftStart, timeShiftEnd);
};

export function getDecimalPart(num: number) {
  if (Number.isInteger(num)) {
    return 0;
  }

  const decimalStr = num.toString().split('.')[1];
  return Number(decimalStr);
}

export const calcTax8PercentIncludeTax = (price?: number) => {
  if (!price) return 0;

  const arrNumberBehindDecimalToGetInteger = ['962962962', '925925925'];

  const divisionNumber = price / 1.08;
  const decimalPart = getDecimalPart(divisionNumber).toString().slice(0, 9);

  const result = (price / 1.08) * NUMBER_PERCENT_TAX.CHECKED;

  const isReturnInteger = arrNumberBehindDecimalToGetInteger.includes(decimalPart);

  if (isReturnInteger) {
    return Math.trunc(result);
  }

  return Math.ceil(result);
};
export const calcTax10PercentIncludeTax = (price?: number) => {
  if (!price) return 0;

  const result = (price / 1.1) * NUMBER_PERCENT_TAX.DEFAULT;

  return Math.ceil(result);
};

export const calcTax8PercentNotIncludeTax = (price?: number) => {
  if (!price) return 0;

  const result = price * NUMBER_PERCENT_TAX.CHECKED;

  return Math.round(result);
};
export const calcTax10PercentNotIncludeTax = (price?: number) => {
  if (!price) return 0;

  const result = price * NUMBER_PERCENT_TAX.DEFAULT;

  return Math.round(result);
};

export const renderRegistrationNumber = (profile?: IProfile, profileCompany?: any) => {
  if (profile?.company?.roleId === UserRole.VENDOR) {
    return profile?.vendor?.registrationNumber;
  }
  if (profile?.company?.roleId === UserRole.ADMIN) {
    return profileCompany?.registrationNumber;
  }

  return profile?.company?.registrationNumber;
};

export const renderRegistrationNumberInPDF = ({
  profile,
  paperType,
  registrationNumber,
  profileCompany,
}: {
  profile: IProfile;
  paperType: PaperSystemType;
  profileCompany: any;
  registrationNumber?: string;
}) => {
  const roleId = profile?.company?.roleId;

  if (paperType === PaperSystemType.PAYMENT_REQUEST) {
    if ([UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.MANAGER].includes(roleId)) {
      return profileCompany?.registrationNumber;
    }

    if (roleId === UserRole.VENDOR) {
      return profile?.vendor?.registrationNumber;
    }
  }

  if (paperType === PaperSystemType.INVOICE) {
    if ([UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.MANAGER].includes(roleId)) {
      return profileCompany?.registrationNumber;
    }

    if (roleId === UserRole.VENDOR) {
      return profile?.vendor?.registrationNumber;
    }
  }

  if (paperType === PaperSystemType.ACCEPTANCE_CONSTRUCTION_SITE) {
    return profileCompany?.registrationNumber;
  }

  if (paperType === PaperSystemType.CREATE_PAYMENT_REQUEST_FOR_OTHER_WORKER) {
    return registrationNumber;
  }

  if (paperType === PaperSystemType.PAYMENT_RECEIPT) {
    return profileCompany?.registrationNumber;
  }

  return '';
};

// use to create metadataPrice for create and update PaymentRequest
export const getMetadataPrice = ({
  tasks,
  isLabo,
  isFixPrice,
  idsTaskChecked,
  idsTaskFixedPriceChecked,
  priceObj,
}: {
  tasks: any[];
  isLabo: boolean;
  isFixPrice: boolean;
  idsTaskChecked: React.Key[];
  idsTaskFixedPriceChecked: React.Key[];
  priceObj: Record<string, any>;
}) => {
  const numberTask = tasks?.length || 0;

  const metadataPrice: IMetadataPrice[] = [];

  if (isLabo) {
    if (idsTaskChecked.length > 0) {
      metadataPrice.push({
        valueTax: PERCENT_TAX.CHECKED,
        subTotal: priceObj.labo.subTotalCheckedLabo,
        tax: priceObj?.labo?.taxCheckedLabo,
        taskIds: idsTaskChecked,
      });
    }
    if (numberTask - idsTaskChecked.length > 0) {
      metadataPrice.push({
        valueTax: PERCENT_TAX.DEFAULT,
        subTotal: priceObj.labo.subTotalNotCheckedLabo,
        tax: priceObj?.labo?.taxUnCheckedLabo,
        taskIds: [],
      });
    }

    return metadataPrice;
  }

  if (isFixPrice) {
    if (idsTaskFixedPriceChecked.length > 0) {
      metadataPrice.push({
        valueTax: PERCENT_TAX.CHECKED,
        subTotal: priceObj?.fixPrice?.subTotalCheckedFixPrice,
        tax: priceObj?.fixPrice?.taxCheckedFixPrice,
        taskIds: idsTaskFixedPriceChecked,
      });
    }

    if (numberTask - idsTaskFixedPriceChecked.length > 0) {
      metadataPrice.push({
        valueTax: PERCENT_TAX.DEFAULT,
        subTotal: priceObj?.fixPrice?.subTotalNotCheckedFixPrice,
        tax: priceObj?.fixPrice?.taxUnCheckedFixPrice,
        taskIds: [],
      });
    }

    return metadataPrice;
  }

  return metadataPrice;
};

// use to get priceObj from metadataPrice
// when get metadataPrice from api
export const getPriceObjFromMetadataPrice = ({
  metadataPrice,
  isTax,
}: {
  metadataPrice?: IMetadataPrice[];
  isTax?: number;
}) => {
  const price8PercentTax = metadataPrice?.find((item) => item?.valueTax === PERCENT_TAX.CHECKED);
  const price10PercentTax = metadataPrice?.find((item) => item?.valueTax === PERCENT_TAX.DEFAULT);

  const priceObj = {
    checkedPriceNotIncludeTax: [],
    checkedPriceIncludeTax: [],
  } as any;

  if (isTax === TaxType.INACTIVE) {
    if (!isNil(price8PercentTax)) {
      priceObj['subtotal8Percent'] = price8PercentTax?.subTotal;
      priceObj['checkedPriceNotIncludeTax'].push(PERCENT_TAX.CHECKED);
    }

    if (!isNil(price10PercentTax)) {
      priceObj['subtotal10Percent'] = price10PercentTax?.subTotal;
      priceObj['checkedPriceNotIncludeTax'].push(PERCENT_TAX.DEFAULT);
    }
  } else {
    if (!isNil(price8PercentTax)) {
      priceObj['subtotal8PercentIncludeTax'] = price8PercentTax?.subTotal;
      priceObj['checkedPriceIncludeTax'].push(PERCENT_TAX.CHECKED);
    }

    if (!isNil(price10PercentTax)) {
      priceObj['subtotal10PercentIncludeTax'] = price10PercentTax?.subTotal;
      priceObj['checkedPriceIncludeTax'].push(PERCENT_TAX.DEFAULT);
    }
  }

  return priceObj;
};

// use to get priceObj from values in form
// used in Invoice
export const getMetadataFromForm = ({ valuesForm }: { valuesForm: Record<string, any> }) => {
  const metadataPrice: IMetadataPrice[] = [];

  const checked8Percent =
    valuesForm?.isTax === TaxType.INACTIVE && valuesForm?.checkedPriceNotIncludeTax?.includes(PERCENT_TAX.CHECKED);
  const checked10Percent =
    valuesForm?.isTax === TaxType.INACTIVE && valuesForm?.checkedPriceNotIncludeTax?.includes(PERCENT_TAX.DEFAULT);

  const checked8PercentIncludeTax =
    valuesForm?.isTax === TaxType.ACTIVE && valuesForm?.checkedPriceIncludeTax?.includes(PERCENT_TAX.CHECKED);
  const checked10PercentIncludeTax =
    valuesForm?.isTax === TaxType.ACTIVE && valuesForm?.checkedPriceIncludeTax?.includes(PERCENT_TAX.DEFAULT);

  if (checked8Percent && !isNil(valuesForm?.subtotal8Percent)) {
    metadataPrice.push({
      valueTax: PERCENT_TAX.CHECKED,
      subTotal: +valuesForm?.subtotal8Percent || 0,
      tax: +valuesForm?.totalTax8Percent || 0,
      taskIds: [],
    });
  }

  if (checked10Percent && !isNil(valuesForm?.subtotal10Percent)) {
    metadataPrice.push({
      valueTax: PERCENT_TAX.DEFAULT,
      subTotal: +valuesForm?.subtotal10Percent || 0,
      tax: +valuesForm?.totalTax10Percent || 0,
      taskIds: [],
    });
  }

  if (checked8PercentIncludeTax && !isNil(valuesForm?.subtotal8PercentIncludeTax)) {
    metadataPrice.push({
      valueTax: PERCENT_TAX.CHECKED,
      subTotal: +valuesForm?.subtotal8PercentIncludeTax || 0,
      tax: 0,
      taskIds: [],
    });
  }

  if (checked10PercentIncludeTax && !isNil(valuesForm?.subtotal10PercentIncludeTax)) {
    metadataPrice.push({
      valueTax: PERCENT_TAX.DEFAULT,
      subTotal: +valuesForm?.subtotal10PercentIncludeTax || 0,
      tax: 0,
      taskIds: [],
    });
  }

  return metadataPrice;
};

export const mathSubtotal = (price?: number) => {
  if (!price) return 0;

  return Math.floor(price);
};

export const convertStringPriceYenToNumber = (stringPrice?: string | number) => {
  if (isNil(stringPrice)) return 0;

  const string = String(stringPrice)?.replace('￥', '')?.replace('¥', '').replace(/,/g, '');

  return Number(string);
};
export const convertStringPriceYenToNumberWithDefault = ({
  stringPrice,
  defaultVal = 0,
}: {
  stringPrice?: string;
  defaultVal?: any;
}) => {
  if (isNil(stringPrice)) return defaultVal;

  const string = String(stringPrice)?.replace('￥', '')?.replace('¥', '').replace(/,/g, '');

  return Number(string);
};

export const getNameBusinessField = (businessField?: number | null) => {
  if (isNil(businessField)) {
    return '';
  }

  return listCompanyBusinessField.find((item) => item.id === businessField)?.name || '';
};

export const getNameKenId = (kenId?: number | null) => {
  if (isNil(kenId)) {
    return '';
  }

  return;
};

export const getTextRoleId = (roleId?: number | null) => {
  if (isNil(roleId)) {
    return '';
  }

  return listRolesStaff.find((item) => item.id === roleId)?.name || '';
};

export const getTitleTimeOffMode = (mode?: TimeOffMode) => {
  if (!mode) return '';

  return listModeTimeOff.find((item) => item.id === mode)?.name || '';
};

export const getTitleDayOffType = (type?: TimeOffType) => {
  if (!type) return '';

  return listDayOffType.find((item) => item.id === type)?.name || '';
};

export const getDayOffInSchedule = (date: string, schedule?: ISchedule) => {
  const dayOff = moment(date).locale('en').format('dddd').toLocaleLowerCase();
  const dayOffInSchedule =
    schedule &&
    Object.entries(schedule)?.find(([key, value]) => {
      return key === dayOff && value === CommonStatus.ACTIVE;
    })?.[0];

  return { dayOffInSchedule };
};

export const getHourTimeOff = (dataTimeOff: ITimeOffData, numberShiftWorking = 0) => {
  const schedule = dataTimeOff?.schedule || {};
  const workingHour = dataTimeOff?.workingHour || 0;

  const { dayOffInSchedule } = getDayOffInSchedule(dataTimeOff?.date, schedule);

  if (isNil(dayOffInSchedule)) {
    return null;
  }

  // lương tháng + lương giờ
  if ([SalaryType.MONTH_SALARY, SalaryType.HOUR_SALARY].includes(dataTimeOff.salaryType)) {
    const hour = schedule?.[`${dayOffInSchedule}Hour` as keyof typeof schedule] || 0;
    const minute = schedule?.[`${dayOffInSchedule}Minute` as keyof typeof schedule] || 0;

    if (dataTimeOff.mode === TimeOffMode.FULL) {
      return `${hour}:${minute}`;
    }

    const totalMinutes = (hour * 60 + minute) / 2;
    const hourCalc = Math.floor(totalMinutes / 60);
    const minutesCalc = Math.floor(totalMinutes - hourCalc * 60);

    return `${hourCalc}:${minutesCalc}`;
  }

  // lương ca
  if (dataTimeOff.salaryType === SalaryType.SHIFT_SALARY) {
    if (dataTimeOff.mode === TimeOffMode.FULL) {
      return `${workingHour * numberShiftWorking || 0}:0`;
    }

    const totalMinutes = (workingHour * numberShiftWorking * 60) / 2;
    const hourCalc = Math.floor(totalMinutes / 60);
    const minutesCalc = Math.floor(totalMinutes - hourCalc * 60);

    return `${hourCalc}:${minutesCalc}`;
  }

  return null;
};

export const getGenderTitle = (genderVal?: Gender) => {
  return listGender.find((item) => item.id === genderVal)?.name;
};

export const getIsSupervisingAdminTitle = (isSupervisingAdmin?: CommonStatus) => {
  return isSupervisingAdmin ? 'はい' : 'いいえ';
};

export const getIsDisableViewRevenueTitle = (isDisableViewRevenue?: CommonStatus) => {
  return isDisableViewRevenue ? 'はい' : 'いいえ';
};

export const handleChangeIsSupervisingAdmin = (form: FormInstance, changedValues?: Record<string, any>) => {
  const isSupervisingAdmin = changedValues?.metadata?.isSupervisingAdmin;

  if (!isNil(isSupervisingAdmin) && isSupervisingAdmin === CommonStatus.ACTIVE) {
    form.setFieldsValue({
      metadata: {
        isDisableViewRevenue: CommonStatus.INACTIVE,
      },
    });
  }
};

export const handleChangeTypePaymentTerm = (form: FormInstance, changedValues?: Record<string, any>) => {
  const typePaymentTerm = changedValues?.metadata?.typePaymentTerm;

  if (!isNil(typePaymentTerm)) {
    form.resetFields([['metadata', 'paymentTerm']]);
  }
};

export const getColorItemTaskWorkerCreated = (tasks: ITask) => {
  const isCustomer = tasks?.creatorRoleId === UserRole.CUSTOMER || tasks?.isCustomer;
  const isFulledShift = tasks?.startTime || tasks?.endTime;
  const isAdminUpdate = tasks?.isAdminUpdate;
  const isCustomerMetadata = !!tasks?.metadataCustomerUpdate;

  if (!isAdminUpdate) {
    if (!isCustomerMetadata) {
      return 'color-red';
    }
    return 'color-orange-6';
  } else {
    if (isCustomerMetadata) {
      return 'color-orange-6';
    }

    if (!isFulledShift) {
      return 'color-red';
    }

    if (isCustomer && isFulledShift) return 'color-purple';
    return '';
  }
};

export const handleMessageSuccess = (msg?: string) => {
  message.destroy();
  message.success({
    content: msg ?? '成功',
    duration: 2,
    style: {
      zIndex: 1001,
    },
  });
};

export const formatTimeToYearAndMonth = (time?: string) => {
  const year = time?.split('-')[0] ?? '';
  const month = time?.split('-')[1] ?? '';

  return `${year}年${month ? month + '月' : ''}`;
};

// reverse enum
export const getReverseEnum = (enumObject: Record<string, any>) => {
  const reversedEnum = {} as Record<string, any>;

  for (const key of Object.keys(enumObject)) {
    reversedEnum[enumObject[key]] = key;
  }

  return reversedEnum;
};

// convert data keys from SqlKeys
// ex : {q : 1 } -> {id : 1}
export const convertDataKeys = (
  data: Record<string, any>,
  enumObject: Record<string, any> = getReverseEnum(KeySQL)
) => {
  const convertedData = {} as Record<string, any>;

  for (const key in data) {
    // if key in enumObject --> get key in enumObject
    // else keep origin key
    const keyConvert = enumObject[key] || key;

    if (isArray(data[key])) {
      convertedData[keyConvert] = data?.[key]?.map((item: any) => {
        if (!isObject(item)) {
          return item;
        }

        return convertDataKeys(item, enumObject);
      });
      continue;
    }

    if (isObject(data[key])) {
      convertedData[keyConvert] = convertDataKeys(data[key], enumObject);
      continue;
    }

    convertedData[keyConvert] = data[key];
  }

  return convertedData;
};

// handle scroll select
export const handleOnPopupScroll = (callback?: () => void) => {
  const windowHeight = window.innerHeight;
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );

  if (scrollTop + windowHeight >= documentHeight - 100) {
    callback && callback();
  }
};

// get value from input with prefix or suffix
// ex : ￥1,234 -> 1234
export const getValueInputSymbol = (value?: string, isPrefix = true) => {
  // remove prefix
  if (isPrefix) {
    return String(value)?.slice(1)?.replace(/,/g, '');
  }

  // remove suffix
  return String(value)?.slice(0, -1)?.replace(/,/g, '');
};

// get value service charge from form
export const getPayloadServiceChargeFromForm = (metadata?: any) => {
  metadata.serviceChargeValue = !isNil(metadata?.serviceChargeValue)
    ? +getValueInputSymbol(metadata?.serviceChargeValue)
    : 0;

  metadata.serviceChargePercent = !isNil(metadata?.serviceChargePercent)
    ? +getValueInputSymbol(metadata?.serviceChargePercent, false)
    : 0;

  return omit(metadata, ['serviceCharge', 'checkboxServiceChargePercent', 'checkboxServiceChargeValue']);
};

// get value service charge from api
export const getPayloadServiceChargeFromApi = (customer: any) => {
  let customerConvert = cloneDeep(customer);

  const serviceCharge = Boolean(customerConvert?.serviceChargeValue || customerConvert?.serviceChargePercent);
  customerConvert.serviceCharge = serviceCharge ? CommonStatus.ACTIVE : CommonStatus.INACTIVE;

  if (!serviceCharge) {
    customerConvert.serviceChargeValue = null;
    customerConvert.serviceChargePercent = null;
  } else {
    // set value for checkbox
    customerConvert.checkboxServiceChargeValue = customerConvert?.serviceChargeValue > 0;
    customerConvert.checkboxServiceChargePercent = customerConvert?.serviceChargePercent > 0;

    // set value for input
    customerConvert.serviceChargeValue =
      customerConvert?.serviceChargeValue > 0
        ? `${CURRENCY_UNIT.YEN}${customerConvert?.serviceChargeValue ?? 0}`
        : null;
    customerConvert.serviceChargePercent =
      customerConvert?.serviceChargePercent > 0
        ? `${customerConvert?.serviceChargePercent ?? 0}${CURRENCY_UNIT.PERCENT}`
        : null;
  }

  return customerConvert;
};

export const calcPercent = (percent: number | null) => {
  if (!percent) return 0;

  return percent / 100;
};

export const checkValuesChanged = ({
  initialValues,
  currentValues,
  keysToCheck = [], // Các trường cần kiểm tra
}: {
  initialValues: Record<string, any>;
  currentValues: Record<string, any>;
  keysToCheck?: string[];
}) => {
  const keys = keysToCheck.length > 0 ? keysToCheck : Object.keys(currentValues);

  const initialValuesPickByKeys = pick(initialValues, keys);

  const keysWithDifferentValues = keys.filter((key) => !isEqual(initialValuesPickByKeys[key], currentValues[key]));

  return {
    hasChanged: keysWithDifferentValues.length > 0,
    differentKeys: keysWithDifferentValues,
  };
};

export const randomColor = (colors = colorsPicker) => {
  const randomIndex = Math.floor(Math.random() * colors.length);

  return colors[randomIndex];
};

export const renderListDayOffs = (dayOffs?: string[]) => {
  if (!dayOffs || dayOffs?.length === 0) return '';

  return dayOffs.map((dayOff) => getFormatDateDataOther(moment(dayOff))).join(', ') + 'を空け。';
};

export const hasClassInChildren = ({ element, className }: { element?: HTMLElement; className: string }) => {
  if (!element) return false;

  for (let i = 0; i < element?.children?.length; i++) {
    const child = element.children[i];

    if (child.classList.contains(className)) {
      return true;
    }
  }
  return false; // Trả về false nếu
};

// check company is expired purchased
export const checkCompanyIsExpiredPurchased = (el: any) => {
  return (
    el?.paymentStatus === STATUS.INACTIVE ||
    (el?.expiredAt !== null && moment(el?.expiredAt).isBefore(moment().format('YYYY-MM-DD')))
  );
};

// check if task is separated maxWorker to maxWorkerMorning and maxWorkerAfternoon
export const checkTaskSeparatedShift = (task?: ITaskDetail) => {
  // neu co maxWorker data cũ thì không phải là task mới
  if (!isNil(task?.maxWorker)) {
    return false;
  }

  const isCheckMorningOrAfternoon = [task?.morningShift, task?.afternoonShift].some((item) => item);
  const isHaveNewData = [task?.maxWorkerMorning, task?.maxWorkerAfternoon].some((item) => !isNil(item));

  if (isCheckMorningOrAfternoon) {
    return isHaveNewData;
  }

  return true;
};

export const getNameCompany = (comment: IComment) => {
  if (!comment) return '';

  const isCustomer = comment?.creatorRoleId === UserRole.CUSTOMER || Boolean(comment?.creatorIsCustomer);

  return isCustomer ? comment?.customerCompanyName : comment?.companyName;
};

export const getWorkersShiftOld = (event: ITask) => {
  const idsWorkerMorningOld =
    event?.workers?.filter((worker: IWorker) => worker.morningShift === 1)?.map((worker) => worker.id) ?? [];
  const idsWorkerAfternoonOld =
    event?.workers?.filter((worker: IWorker) => worker.afternoonShift === 1)?.map((worker) => worker.id) ?? [];
  const idsWorkerEveningOld =
    event?.workers?.filter((worker: IWorker) => worker.eveningShift === 1)?.map((worker) => worker.id) ?? [];
  const idsWorkerOtherOld =
    event?.workers?.filter((worker: IWorker) => worker.otherShift === 1)?.map((worker) => worker.id) ?? [];

  const unitPriceOld = event?.workers
    ?.filter((worker: IWorker) => worker.otherShift === 1)
    ?.map((worker: IWorker) => ({
      userId: worker.id,
      price: worker.price ?? 0,
    }));

  return {
    idsWorkerMorningOld,
    idsWorkerAfternoonOld,
    idsWorkerEveningOld,
    idsWorkerOtherOld,
  };
};

function getBreakTime({ timeStr, timeSlots }: { timeStr: string; timeSlots: IBreakTime[] }) {
  // Chuyển đổi thời gian hh:mm thành số phút kể từ 00:00
  const [hour, minute] = timeStr.split(':').map(Number);
  const timeInMinutes = hour * 60 + minute;

  // Duyệt qua các phần tử trong mảng và xác định khoảng thời gian tương ứng
  for (let i = 0; i < timeSlots.length; i++) {
    const slotStart = timeSlots[i].hour * 60 + timeSlots[i].minute;
    const slotEnd = i + 1 < timeSlots.length ? timeSlots[i + 1].hour * 60 + timeSlots[i + 1].minute : Infinity;

    if (timeInMinutes >= slotStart && timeInMinutes < slotEnd) {
      return timeSlots[i].break;
    }
  }

  // Trường hợp thời gian trước mốc đầu tiên
  if (timeInMinutes < timeSlots?.[0]?.hour * 60 + timeSlots?.[0]?.minute) {
    return 0;
  }

  // Trường hợp thời gian sau mốc cuối cùng
  return timeSlots?.[timeSlots?.length - 1]?.break;
}

// calc default break time
export const getDefaultBreakTime = ({
  profileCompany,
  roleId,
  totalRawDuration,
}: {
  profileCompany: ICompanyProfile;
  roleId: UserRole;
  totalRawDuration: string; // hh : mm
}) => {
  const breakTimesBasedRole = profileCompany?.breakTime?.filter((item) => item.roleId === roleId) ?? [];

  const breakTime = getBreakTime({ timeSlots: breakTimesBasedRole, timeStr: totalRawDuration });

  return breakTime ?? 0;
};

const isSameCertificate = (certificate1: ICertificate, certificate2: ICertificate) => {
  const arrKeys: (keyof ICertificate)[] = ['id', 'price', 'status', 'title', 'userId'];

  for (const key of arrKeys) {
    if (certificate1?.[key] !== certificate2?.[key]) {
      return false;
    }
  }

  return true;
};

export const getCertificates = ({ profile, shift, task }: { profile: IProfile; task: any; shift: TShift }) => {
  // nếu là vendor
  if (profile?.company?.roleId === UserRole.VENDOR) {
    return task?.metadataUnitPriceCertificate;
  }

  // nếu shift là morning || afternoon thì lấy certificate của cả morning và afternoon
  if (shift === 'morningShift' || shift === 'afternoonShift') {
    const mergedCertificates = [
      ...(task?.metadataCustomerUnitPriceCertificate?.morningShift ?? []),
      ...(task?.metadataCustomerUnitPriceCertificate?.afternoonShift ?? []),
    ];

    return mergedCertificates.reduce((certificates: ICertificate[], certificate: ICertificate) => {
      const foundIndex = certificates.findIndex((item) => isSameCertificate(item, certificate));

      if (foundIndex === -1) {
        return [...certificates, certificate];
      }

      // add total workers if certificate is same
      const newTotalWorkers = (certificates?.[foundIndex]?.workers ?? 0) + (certificate?.workers ?? 0);

      // new certificates
      certificates[foundIndex] = {
        ...certificates[foundIndex],
        workers: newTotalWorkers,
      };

      return certificates;
    }, []);
  }

  // other case : eveningShift | otherShift
  return task?.metadataCustomerUnitPriceCertificate?.[shift];
};

export const getUserVendorWorkingTypeByShift = (shift: TShift) => {
  if (shift === 'morningShift') {
    return UserVendorWorkingType.MORNING;
  }

  if (shift === 'afternoonShift') {
    return UserVendorWorkingType.AFTERNOON;
  }

  if (shift === 'eveningShift') {
    return UserVendorWorkingType.NIGHT;
  }

  if (shift === 'otherShift') {
    return UserVendorWorkingType.OTHER;
  }
};

export const getGap = (gap?: number | [number, number]) => {
  if (isNil(gap))
    return {
      rowGap: 'normal',
      columnGap: 'normal',
    };

  if (Array.isArray(gap)) {
    return {
      rowGap: `${gap[0]}px`,
      columnGap: `${gap[1]}px`,
    };
  }
  return {
    rowGap: `${gap}px`,
    columnGap: `${gap}px`,
  };
};

export const formatNote = (note: IComment) => {
  const morningShift = note?.isMorningShift ? `午前${note?.morningExtraWorker}` : '';
  const afternoonShift = note?.isAfternoonShift ? `午後${note?.afternoonExtraWorker}` : '';
  const eveningShift = note?.isEveningShift ? `夜間${note?.eveningExtraWorker}` : '';
  const otherShift = note?.isOtherShift ? `オプション${note?.otherExtraWorker}` : '';
  const preRecap = [morningShift, afternoonShift, eveningShift, otherShift].filter((item) => item).join('/');
  const time = [note?.time, note?.endTime].filter((item) => item).join('-');
  const recap = `${preRecap}  ${time}`;
  return recap;
};

export const convertStringWorkerToNumber = (data: Record<string, any> = {}) => {
  const convertKeys = [
    'workerMorning',
    'workerAfternoon',
    'workerEvening',
    'workerOther',
    'maxWorker',
    'maxWorkerMorning',
    'maxWorkerAfternoon',
    'maxWorkerEvening',
    'maxWorkerOther',
  ];

  const dataConvert = Object.keys(data).reduce((acc, key) => {
    const currentValue = data?.[key as keyof typeof data];

    if (convertKeys.includes(key)) {
      const convertValue = isNil(currentValue) ? currentValue : Number(currentValue);

      return { ...acc, [key]: convertValue };
    }

    return { ...acc, [key]: currentValue };
  }, {});

  return dataConvert;
};

export const roundNumberWorkerTask = (value: number) => {
  if (!value) return 0; // default value

  return Math.floor(value);
};

export const isFloat = (n: number) => {
  return n % 1 !== 0;
};

export const roundToTwoDecimal = (num: number) => Math.round(num * 100) / 100;

export const roundPricePaper = (value?: number) => {
  if (!value) return 0;

  return numeral(value).format('0.[00]');
};
