import { Form } from 'antd';

const ErrorCheckBoxShift = () => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, getFieldError }) => {
        const errorMorningShift = getFieldError('morningShift')?.[0];
        const errorAfternoonShift = getFieldError('afternoonShift')?.[0];
        const errorEveningShift = getFieldError('eveningShift')?.[0];
        const errorOtherShift = getFieldError('otherShift')?.[0];

        const isNotSelectAtLeastOneShift = [
          errorMorningShift,
          errorAfternoonShift,
          errorEveningShift,
          errorOtherShift,
        ].some((x) => x);

        if (isNotSelectAtLeastOneShift)
          return <div className="text-error strong">少なくとも1つのシフトを選択してください。</div>;

        return null;
      }}
    </Form.Item>
  );
};

export default ErrorCheckBoxShift;
