import { IMetaDataOrigin, IMetaDataUpdate } from './index';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import styles from './styles.module.scss';
import { Space, Typography } from 'antd';
import { checkTaskSeparatedShift, convertStringWorkerToNumber } from 'helper';
import { titlesShift } from 'common/const';

interface IProps {
  dataUpdate?: IMetaDataUpdate;
  dataOrigin?: IMetaDataOrigin;
}

const valueNotChange = '変更なし';
const valueNotExists = 'なし';

const BodyHistoryUpdate = ({ dataUpdate = {}, dataOrigin = {} }: IProps) => {
  const isFirstTimeCreateTask = isEmpty(dataOrigin);

  const dataOriginConvert = convertStringWorkerToNumber({ ...dataOrigin }) as IMetaDataOrigin;
  const dataUpdateConvert = convertStringWorkerToNumber({ ...dataUpdate }) as IMetaDataUpdate;

  const valueDefault = isFirstTimeCreateTask ? valueNotExists : valueNotChange;

  const metadataCustomerUnitPriceCertificate = dataUpdateConvert?.metadataCustomerUnitPriceCertificate
    ? (JSON.parse(
        dataUpdateConvert?.metadataCustomerUnitPriceCertificate
      ) as IMetaDataOrigin['metadataCustomerUnitPriceCertificate'])
    : null;

  // check is update max-worker and certificate morning + afternoon
  const isUpdateCertificate = !isNil(metadataCustomerUnitPriceCertificate?.morningShift);
  const isUpdateMaxWorker =
    !isNil(dataUpdateConvert?.maxWorker) && dataUpdateConvert?.maxWorker !== dataOriginConvert?.maxWorker;

  // check is update max-worker and certificate morning
  const isUpdateCertificateMorning = !isNil(metadataCustomerUnitPriceCertificate?.morningShift);
  const isUpdateMaxWorkerMorning =
    !isNil(dataUpdateConvert?.maxWorkerMorning) &&
    dataUpdateConvert?.maxWorkerMorning !== dataOriginConvert?.maxWorkerMorning;

  // check is update max-worker and certificate afternoon
  const isUpdateCertificateAfternoon = !isNil(metadataCustomerUnitPriceCertificate?.afternoonShift);
  const isUpdateMaxWorkerAfternoon =
    !isNil(dataUpdateConvert?.maxWorkerAfternoon) &&
    dataUpdateConvert?.maxWorkerAfternoon !== dataOriginConvert?.maxWorkerAfternoon;

  // check is update max-worker and certificate evening
  const isUpdateCertificateEvening = !isNil(metadataCustomerUnitPriceCertificate?.eveningShift);
  const isUpdateMaxWorkerEvening =
    !isNil(dataUpdateConvert?.maxWorkerEvening) &&
    dataUpdateConvert?.maxWorkerEvening !== dataOriginConvert?.maxWorkerEvening;

  // check is update max-worker and certificate other
  const isUpdateCertificateOther = !isNil(metadataCustomerUnitPriceCertificate?.otherShift);
  const isUpdateMaxWorkerOther =
    !isNil(dataUpdateConvert?.maxWorkerOther) &&
    dataUpdateConvert?.maxWorkerOther !== dataOriginConvert?.maxWorkerOther;

  // calc default value for max-worker morning + afternoon
  const valueMaxWorkerUpdateMorningAndAfterNoon = () => {
    if (isUpdateMaxWorker) {
      return dataUpdateConvert?.maxWorker;
    }

    // cases : not update max-worker
    if (isUpdateCertificate) {
      return dataOriginConvert?.maxWorker;
    }

    return valueDefault;
  };

  // calc default value for max-worker morning
  const valueMaxWorkerUpdateMorning = () => {
    if (isUpdateMaxWorkerMorning) {
      return dataUpdateConvert?.maxWorkerMorning;
    }

    // cases : not update max-worker
    if (isUpdateCertificateMorning) {
      return dataOriginConvert?.maxWorkerMorning;
    }

    return valueDefault;
  };

  // calc default value for max-worker afternoon
  const valueMaxWorkerUpdateAfternoon = () => {
    if (isUpdateMaxWorkerAfternoon) {
      return dataUpdateConvert?.maxWorkerAfternoon;
    }

    // cases : not update max-worker
    if (isUpdateCertificateAfternoon) {
      return dataOriginConvert?.maxWorkerAfternoon;
    }

    return valueDefault;
  };

  // calc default value for max-worker evening
  const valueMaxWorkerUpdateEvening = () => {
    if (isUpdateMaxWorkerEvening) {
      return dataUpdateConvert?.maxWorkerEvening;
    }

    // cases : not update max-worker
    if (isUpdateCertificateEvening) {
      return dataOriginConvert?.maxWorkerEvening;
    }

    return valueDefault;
  };

  // calc default value for max-worker other
  const valueMaxWorkerUpdateOther = () => {
    if (isUpdateMaxWorkerOther) {
      return dataUpdateConvert?.maxWorkerOther;
    }

    // cases : not update max-worker
    if (isUpdateCertificateOther) {
      return dataOriginConvert?.maxWorkerOther;
    }

    return valueDefault;
  };

  const isShowMaxWorkerMorningAndAfternoon = () => {
    return isUpdateCertificate || isUpdateMaxWorker;
  };

  const isShowMaxWorkerMorning = () => {
    return isUpdateCertificateMorning || isUpdateMaxWorkerMorning;
  };

  const isShowMaxWorkerAfternoon = () => {
    return isUpdateCertificateAfternoon || isUpdateMaxWorkerAfternoon;
  };

  const isShowMaxWorkerEvening = () => {
    return isUpdateCertificateEvening || isUpdateMaxWorkerEvening;
  };

  const isShowMaxWorkerOther = () => {
    return isUpdateCertificateOther || isUpdateMaxWorkerOther;
  };

  const isShowDataMaxWorker =
    [
      isShowMaxWorkerMorningAndAfternoon(),
      isShowMaxWorkerMorning(),
      isShowMaxWorkerAfternoon(),
      isShowMaxWorkerEvening(),
      isShowMaxWorkerOther(),
    ].some(Boolean) || !isNil(dataUpdateConvert?.metadataCustomerUnitPriceCertificate);

  const isNewData = checkTaskSeparatedShift(dataUpdateConvert as any);

  return (
    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* Note công việc */}
      {!isNil(dataUpdateConvert?.description) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>仕事の内容</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && <>{dataOriginConvert?.description || valueNotExists} -&gt;</>}
            {!isNil(dataUpdateConvert?.description) ? dataUpdateConvert?.description : valueDefault}
          </div>
        </div>
      )}

      {/* Files thay đổi */}
      {!isNil(dataUpdateConvert?.files) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>アップロードされたファイル</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                <Space direction="vertical" size={1}>
                  {dataOriginConvert?.files?.map((file, index) => (
                    <Typography.Link target="_blank" href={file.url} key={index}>
                      {file?.name}
                    </Typography.Link>
                  )) || valueNotExists}{' '}
                </Space>
                -&gt;
              </>
            )}

            {!isNil(dataUpdateConvert?.files) ? (
              <Space direction="vertical" size={1}>
                {dataUpdateConvert?.files?.map((file, index) => (
                  <Typography.Link target="_blank" href={file.url} key={index}>
                    {file?.name}
                  </Typography.Link>
                ))}
              </Space>
            ) : (
              valueDefault
            )}
          </div>
        </div>
      )}

      {/* Ca làm việc của task */}
      {[
        dataUpdateConvert?.afternoonShift,
        dataUpdateConvert?.morningShift,
        dataUpdateConvert?.eveningShift,
        dataUpdateConvert?.otherShift,
      ].some((item) => !isNil(item)) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>プロジェクト時間</div>
          <div className={styles.colFull}>
            {isFirstTimeCreateTask ? (
              <div>{Boolean(dataUpdateConvert?.morningShift) && '午前をチェック'}</div>
            ) : (
              !isNil(dataOriginConvert?.morningShift) &&
              !isNil(dataUpdateConvert?.morningShift) &&
              dataOriginConvert?.morningShift !== dataUpdateConvert?.morningShift && (
                <div>
                  {dataOriginConvert?.morningShift ? '午前をチェック' : valueNotExists} -&gt;{' '}
                  {dataUpdateConvert?.morningShift ? '午前をチェック' : valueNotExists}
                </div>
              )
            )}

            {isFirstTimeCreateTask ? (
              <div>{Boolean(dataUpdateConvert?.afternoonShift) && '午後をチェック'}</div>
            ) : (
              !isNil(dataOriginConvert?.afternoonShift) &&
              !isNil(dataUpdateConvert?.afternoonShift) &&
              dataOriginConvert?.afternoonShift !== dataUpdateConvert?.afternoonShift && (
                <div>
                  {dataOriginConvert?.afternoonShift ? '午後をチェック' : valueNotExists} -&gt;{' '}
                  {dataUpdateConvert?.afternoonShift ? '午後をチェック' : valueNotExists}
                </div>
              )
            )}

            {isFirstTimeCreateTask ? (
              <div>{Boolean(dataUpdateConvert?.eveningShift) && '夜間をチェック'}</div>
            ) : (
              !isNil(dataOriginConvert?.eveningShift) &&
              !isNil(dataUpdateConvert?.eveningShift) &&
              dataOriginConvert?.eveningShift !== dataUpdateConvert?.eveningShift && (
                <div>
                  {dataOriginConvert?.eveningShift ? '夜間をチェック' : valueNotExists} -&gt;{' '}
                  {dataUpdateConvert?.eveningShift ? '夜間をチェック' : valueNotExists}
                </div>
              )
            )}

            {isFirstTimeCreateTask ? (
              <div>{Boolean(dataUpdateConvert?.otherShift) && 'オプションをチェック'}</div>
            ) : (
              !isNil(dataOriginConvert?.otherShift) &&
              !isNil(dataUpdateConvert?.otherShift) &&
              dataOriginConvert?.otherShift !== dataUpdateConvert?.otherShift && (
                <div>
                  {dataOriginConvert?.otherShift ? 'オプションをチェック' : valueNotExists} -&gt;{' '}
                  {dataUpdateConvert?.otherShift ? 'オプションをチェック' : valueNotExists}
                </div>
              )
            )}

            {/* {Boolean(dataUpdateConvert?.morningShift) && <div>午前をチェック</div>}
            {Boolean(dataUpdateConvert?.afternoonShift) && <div>午後をチェック</div>}
            {Boolean(dataUpdateConvert?.eveningShift) && <div>夜間をチェック</div>}
            {Boolean(dataUpdateConvert?.otherShift) && <div>オプションをチェック</div>} */}
          </div>
        </div>
      )}

      {/* Tên công việc */}
      {!isNil(dataUpdateConvert?.name) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>手配名</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && <>{dataOriginConvert?.name || valueNotExists} -&gt;</>}
            {dataUpdateConvert?.name || valueDefault}
          </div>
        </div>
      )}

      {/* Số người cần thiết cho công việc  + Chứng chỉ khách hàng*/}

      {isShowDataMaxWorker && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>人工</div>
          <div className={styles.colFull}>
            {!isNewData && (
              <div style={{ display: 'flex' }}>
                <div style={{ minWidth: '150px' }}>
                  {isShowMaxWorkerMorningAndAfternoon() && !isFirstTimeCreateTask && (
                    <>
                      {titlesShift.allDay}：{dataOriginConvert?.maxWorker || valueNotExists}
                    </>
                  )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {dataOriginConvert?.metadataCustomerUnitPriceCertificate?.morningShift?.map((item) => (
                    <div key={item?.id}>
                      {item?.title} : {item?.workers}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {isNewData && (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ minWidth: '150px' }}>
                    {isShowMaxWorkerMorning() && !isFirstTimeCreateTask && (
                      <>
                        {titlesShift.morningShift} : {dataOriginConvert?.maxWorkerMorning || valueNotExists}
                      </>
                    )}
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {dataOriginConvert?.metadataCustomerUnitPriceCertificate?.morningShift?.map((item) => (
                      <div key={item?.id}>
                        {item?.title} : {item?.workers}
                      </div>
                    ))}
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div style={{ minWidth: '150px' }}>
                    {isShowMaxWorkerAfternoon() && !isFirstTimeCreateTask && (
                      <>
                        {titlesShift.afternoonShift} : {dataOriginConvert?.maxWorkerAfternoon || valueNotExists}
                      </>
                    )}
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {dataOriginConvert?.metadataCustomerUnitPriceCertificate?.afternoonShift?.map((item) => (
                      <div key={item?.id}>
                        {item?.title} : {item?.workers}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: '150px' }}>
                {isShowMaxWorkerEvening() && !isFirstTimeCreateTask && (
                  <>
                    {titlesShift.eveningShift} : {dataOriginConvert?.maxWorkerEvening || valueNotExists}
                  </>
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {dataOriginConvert?.metadataCustomerUnitPriceCertificate?.eveningShift?.map((item) => (
                  <div key={item?.id}>
                    {item?.title} : {item?.workers}
                  </div>
                ))}
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: '150px' }}>
                {isShowMaxWorkerOther() && !isFirstTimeCreateTask && (
                  <>
                    {titlesShift.otherShift} : {dataOriginConvert?.maxWorkerOther || valueNotExists}
                  </>
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {dataOriginConvert?.metadataCustomerUnitPriceCertificate?.otherShift?.map((item) => (
                  <div key={item?.id}>
                    {item?.title} : {item?.workers}
                  </div>
                ))}
              </div>
            </div>

            {!isFirstTimeCreateTask && <div> -&gt; </div>}

            {!isNewData && (
              <div style={{ display: 'flex' }}>
                <div style={{ minWidth: '150px' }}>
                  {isShowMaxWorkerMorningAndAfternoon() && (
                    <>
                      {titlesShift.allDay}：{valueMaxWorkerUpdateMorningAndAfterNoon()}
                    </>
                  )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* Thay đổi chứng chỉ */}
                  {!isNil(metadataCustomerUnitPriceCertificate?.morningShift) &&
                    metadataCustomerUnitPriceCertificate?.morningShift?.map((item) => (
                      <div key={item?.id}>
                        {item?.title} : {item?.workers}
                      </div>
                    ))}

                  {/* Chỉ thay đổi max-worker , Không thay đổi chứng chỉ */}
                  {isNil(metadataCustomerUnitPriceCertificate?.morningShift) &&
                    dataOriginConvert?.metadataCustomerUnitPriceCertificate?.morningShift?.map((item) => (
                      <div key={item?.id}>
                        {item?.title} : {valueDefault}
                      </div>
                    ))}
                </div>
              </div>
            )}

            {isNewData && (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ minWidth: '150px' }}>
                    {isShowMaxWorkerMorning() && (
                      <>
                        {titlesShift.morningShift} : {valueMaxWorkerUpdateMorning()}
                      </>
                    )}
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Thay đổi chứng chỉ */}
                    {!isNil(metadataCustomerUnitPriceCertificate?.morningShift) &&
                      metadataCustomerUnitPriceCertificate?.morningShift?.map((item) => (
                        <div key={item?.id}>
                          {item?.title} : {item?.workers}
                        </div>
                      ))}

                    {/* Chỉ thay đổi max-worker , Không thay đổi chứng chỉ */}
                    {isNil(metadataCustomerUnitPriceCertificate?.morningShift) &&
                      dataOriginConvert?.metadataCustomerUnitPriceCertificate?.morningShift?.map((item) => (
                        <div key={item?.id}>
                          {item?.title} : {valueDefault}
                        </div>
                      ))}
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div style={{ minWidth: '150px' }}>
                    {isShowMaxWorkerAfternoon() && (
                      <>
                        {titlesShift.afternoonShift} : {valueMaxWorkerUpdateAfternoon()}
                      </>
                    )}
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Thay đổi chứng chỉ */}
                    {!isNil(metadataCustomerUnitPriceCertificate?.afternoonShift) &&
                      metadataCustomerUnitPriceCertificate?.afternoonShift?.map((item) => (
                        <div key={item?.id}>
                          {item?.title} : {item?.workers}
                        </div>
                      ))}

                    {/* Chỉ thay đổi max-worker , Không thay đổi chứng chỉ */}
                    {isNil(metadataCustomerUnitPriceCertificate?.afternoonShift) &&
                      dataOriginConvert?.metadataCustomerUnitPriceCertificate?.afternoonShift?.map((item) => (
                        <div key={item?.id}>
                          {item?.title} : {valueDefault}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}

            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: '150px' }}>
                {isShowMaxWorkerEvening() && <>夜間 : {valueMaxWorkerUpdateEvening()}</>}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* Thay đổi chứng chỉ */}
                {!isNil(metadataCustomerUnitPriceCertificate?.eveningShift) &&
                  metadataCustomerUnitPriceCertificate?.eveningShift?.map((item) => (
                    <div key={item?.id}>
                      {item?.title} : {item?.workers}
                    </div>
                  ))}

                {/* Chỉ thay đổi max-worker , Không thay đổi chứng chỉ */}
                {isNil(metadataCustomerUnitPriceCertificate?.eveningShift) &&
                  dataOriginConvert?.metadataCustomerUnitPriceCertificate?.eveningShift?.map((item) => (
                    <div key={item?.id}>
                      {item?.title} : {valueDefault}
                    </div>
                  ))}
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: '150px' }}>
                {isShowMaxWorkerOther() && <>オプション :{valueMaxWorkerUpdateOther()}</>}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* Thay đổi chứng chỉ */}
                {!isNil(metadataCustomerUnitPriceCertificate?.otherShift) &&
                  metadataCustomerUnitPriceCertificate?.otherShift?.map((item) => (
                    <div key={item?.id}>
                      {item?.title} : {item?.workers}
                    </div>
                  ))}

                {/* Chỉ thay đổi max-worker , Không thay đổi chứng chỉ */}
                {isNil(metadataCustomerUnitPriceCertificate?.otherShift) &&
                  dataOriginConvert?.metadataCustomerUnitPriceCertificate?.otherShift?.map((item) => (
                    <div key={item?.id}>
                      {item?.title} : {valueDefault}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* note công trường */}
      {!isNil(dataUpdateConvert?.noteConstructionSite) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>プロジェクト概要・メモ</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && <>{dataOriginConvert?.noteConstructionSite || valueNotExists} -&gt; </>}

            {!isNil(dataUpdateConvert?.noteConstructionSite) ? dataUpdateConvert?.noteConstructionSite : valueDefault}
          </div>
        </div>
      )}

      {/* Ngày bắt đầu/ ngày kết thúc seri task */}
      {!isNil(dataUpdateConvert?.date) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>開始日 ‐ 終了日</div>

          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                <div>
                  開始日 :{' '}
                  {dataOriginConvert?.date?.from
                    ? moment(dataOriginConvert.date.from).format('YYYY/MM/DD')
                    : valueNotExists}
                </div>
                <div>
                  終了日 :{' '}
                  {dataOriginConvert?.date?.to
                    ? moment(dataOriginConvert.date.to).format('YYYY/MM/DD')
                    : valueNotExists}
                </div>

                <div>-&gt;</div>
              </>
            )}
            <div>
              開始日 :{' '}
              {dataUpdateConvert?.date?.from && dataUpdateConvert.date.from !== dataOriginConvert?.date?.from
                ? moment(dataUpdateConvert.date.from).format('YYYY/MM/DD')
                : valueDefault}
            </div>
            <div>
              終了日 :{' '}
              {dataUpdateConvert?.date?.to && dataUpdateConvert.date.to !== dataOriginConvert?.date?.to
                ? moment(dataUpdateConvert.date.to).format('YYYY/MM/DD')
                : valueDefault}
            </div>
          </div>
        </div>
      )}

      {(dataUpdateConvert?.startTimeDesire || dataUpdateConvert?.endTimeDesire) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>希望時間</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                <span>
                  {dataOriginConvert?.startTimeDesire || valueNotExists} :{' '}
                  {dataOriginConvert?.endTimeDesire || valueNotExists}
                </span>
                <span> -&gt; </span>
              </>
            )}

            <span>
              {dataUpdateConvert?.startTimeDesire || (isFirstTimeCreateTask ? valueNotExists : valueDefault)} :{' '}
              {dataUpdateConvert?.endTimeDesire || (isFirstTimeCreateTask ? valueNotExists : valueDefault)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BodyHistoryUpdate;
