const icons = {
  download: {
    gray: require('./icons/download.svg').default,
  },
  file: {
    gray: require('./icons/pin.svg').default,
    black: require('./icons/pin-black.svg').default,
    white: require('./icons/pin-white.svg').default,
  },
  eye: {
    gray: require('./icons/eye.svg').default,
  },
  arrow: {
    drag: require('./icons/ic_arrow-drag.png').default,
  },
} as const;

export default icons;
