import { IFilter, IParamsGetCompanySubsidy, IProfile, TResCompanySubsidy, TResListCompany } from 'common/interface';
import { axiosInstance, sendGet, sendPost, sendPut } from './axios';
import { IDetailRegisterToken } from 'types/profile.type';

// eslint-disable-next-line import/prefer-default-export
export const loadProfile = () => sendPost('/user/profile');
export const loadProfileCustomer = (payload: any) => sendPost('/user/profile-customer', payload);
export const updateProfile = (payload: any) => sendPut('/user/profile', payload);
export const updateProfileVendor = (payload: any) => sendPut('/user/profile-vendor', payload);
export const updateProfileCustomer = (payload: any) => sendPut('/user/profile-customer', payload);
export const getDetailUser = (id: number) => sendPost(`/user/${id}/details-user`);
export const updateUser = (id: number, data: any) => sendPost(`/user/update-user/${id}`, data);

export const getListApprovedUser = (filter: Partial<IFilter>) => sendPost('/user/list-user', filter);
export const updateApprovedUser = (id: number, payload: Partial<IProfile>) =>
  sendPost(`/user/update-user/${id}`, payload);
export const createApprovedUser = (payload: Partial<IProfile>) => sendPost('/user/create-admin', payload);
export const searchCompany = () => sendPost<TResListCompany>('/auth/search-company');
export const userRegister = (payload: any) => sendPost('/auth/register', payload);
export const profileCompany = async (companyId: any) => {
  const response = await axiosInstance.get(`/v2/company/${companyId}/details`, {
    headers: {
      companyId,
    },
  });

  return response?.data;
};

export const createCompany = (payload: any) => sendPost('/v2/company/create-company', payload);
export const requestOTP = (payload: any) => sendPost('/auth/request-otp', payload);
export const checkOTP = (payload: any) => sendPost('/auth/check-otp', payload);
export const checkEmailExist = (payload: any) => sendPost('/auth/check-email-exist', payload);
export const changePassword = (payload: any) => sendPost('/auth/change-password', payload);
export const forgotPassword = (payload: any) => sendPost('/auth/forgot-password', payload);
export const activeEmail = (payload: any) => sendPost('/auth/active-email', payload);
export const detailRegisterToken = (payload: any) =>
  sendPost<{ data: IDetailRegisterToken }>('/auth/detail-register-token', payload);
export const inviteAdminSignUp = (payload: any) => sendPost('/user/invite-admin', payload);

export const getListCompany = (filter: Partial<IFilter>) => sendPost('/user/list-company', filter);
export const updateCompanyStatus = (payload: any) => sendPost('/user/update-company-status', payload);
export const getDetailCompany = (id: any) => sendPost(`/user/detail-company/${id}`);
export const updateCompany = (data: any) => sendPut(`/user/company-profile`, data);

export const getListInvite = (filter: Partial<IFilter>) => sendPost('/user/list-invite-request', filter);

// v2
export const updateCompanyV2 = (data: any) => sendPut('/v2/company', data);
export const updateCompanyConfig = (data: any) => sendPut('/v2/company/config-shift', data);
export const getListHistoryUpdate = (data: any) => sendPost('/v2/company/get-list-company-shift', data);
export const getDetailHistoryConfigCompany = (id: number | undefined) =>
  sendGet(`/v2/company/get-detail-company-shift/${id}`);

export const getCompanySubsidy = () => sendGet('/v2/company/salary-subsidy');
export const updateCompanySubsidy = (payload: any) => sendPut('/v2/company/salary-subsidy', payload);

export const cancelPayment = () => sendPost('/v2/payment/cancel-recurring');

export const updatePaymentCompany = (payload: any) => sendPut('/v2/company/update-subscription-type', payload);
export const suspendAccount = () => sendPost('v3/user/close-account');

export const getCompanySubsidyV6 = (params?: IParamsGetCompanySubsidy) =>
  sendGet<TResCompanySubsidy>('/v6/company/subsidy', params);
