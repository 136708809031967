import { ITaskDetail } from 'common/interface';
import { useMemo } from 'react';

interface IProps {
  taskDetail?: ITaskDetail;
}

const useIsAppearMetadata = ({ taskDetail }: IProps) => {
  const metadataCustomerUpdate = useMemo(() => {
    const metadata =
      taskDetail?.metadataCustomerUpdate && Object.keys(taskDetail?.metadataCustomerUpdate)
        ? JSON.parse(taskDetail?.metadataCustomerUpdate)
        : null;

    return metadata;
  }, [taskDetail]);

  const isAppearMetadata = (key: string) => {
    return metadataCustomerUpdate && (taskDetail as any)?.[key] != ((metadataCustomerUpdate as any)?.[key] as any);
  };

  return {
    isAppearMetadata,
    metadataCustomerUpdate,
  };
};

export default useIsAppearMetadata;
