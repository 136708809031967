import { IFilter, IResGetDetailsVendor } from 'common/interface';
import { sendPost, sendPut } from './axios';

export const getListVendor = (filter: Partial<IFilter>) => sendPost('/vendor/list-vendor', filter);
export const getDetailVendor = (id: number) => sendPost<IResGetDetailsVendor>(`/vendor/${id}`);
export const getListVendorPaper = (id: number, filter: Partial<IFilter>) =>
  sendPost(`/vendor/${id}/list-paper`, filter);
export const getListVendorConstruction = (id: number, filter: Partial<IFilter>) =>
  sendPost(`/vendor/${id}/list-construction`, filter);
export const getListVendorStaff = (id: number, filter: Partial<IFilter>) =>
  sendPost(`/vendor/${id}/list-staff`, filter);
export const updateVendor = (id: number, payload: any) => sendPut(`/vendor/${id}`, payload);
export const changeStatusVendor = (payload: any) => sendPut('/vendor/update-status', payload);
export const checkEmailVendor = (payload: any) => sendPost('/vendor/check-vendor', payload);
export const companySendPaper = () => sendPost('/paper/send-company');
export const companyReceiverPaper = () => sendPost('/paper/receiver-company');

export const inviteVendor = (payload: any) => sendPut('/vendor/invite-vendor', payload);
export const detailInviteVendor = (payload: any) => sendPost('/vendor/invite-vendor-detail', payload);
export const createVendor = (payload: any) => sendPut('/vendor/accept-invite', payload);
export const createVendorPaper = (payload: any) => sendPut('/vendor/create-paper', payload);
