import { Col, Form, FormInstance, Radio, RadioChangeEvent, Row } from 'antd';
import { TypeTaskArrangement } from 'common';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { useRulesForm } from 'hooks/useRulesForm';
import moment, { Moment } from 'moment';
import useToggleModal from 'hooks/useToggleModal';
import ModalDateExcludeTask from '../ModalDateExcludeTask';
import IconAddDateExcludeTask from '../IconAddDateExcludeTask';
import React from 'react';
import ListDayOffsSelected from '../ListDayOffsSelected';
import { xor } from 'lodash';

interface IProps {
  form?: FormInstance;
  disableStartDate?: boolean;
  disableEndDate?: boolean;
  hideIconAdd?: boolean;
  disableCheckBoxMultiDays?: boolean;
}

export const nameTempTypeDate = 'temp_type_date';

const TimeTaskPicker = ({ form, disableStartDate, disableEndDate, hideIconAdd, disableCheckBoxMultiDays }: IProps) => {
  const { isOpen, toggleModal } = useToggleModal();

  const { rulesForm } = useRulesForm();

  const handleChangeListDayOff = () => {
    const startDate = form?.getFieldValue('startDate');
    const endDate = form?.getFieldValue('endDate');
    const dayOffs = (form?.getFieldValue('dayOffs') as { format: Moment }[]) || [];

    // remove item in list dayOffs is not in range startDate and endDate
    const newDayOffs = dayOffs.filter((item) => {
      const date = item.format;
      return date.isBetween(startDate, endDate);
    });

    form?.setFieldsValue({ dayOffs: newDayOffs });
  };

  const handleChangeStartDate = (value: Date | null) => {
    // form?.setFieldsValue({
    //   endDate: moment(value).add(1, 'days'),
    // });

    handleChangeListDayOff();
  };

  const handleChangeEndDate = () => {
    const startDate = form?.getFieldValue('startDate');
    const endDate = form?.getFieldValue('endDate');
    const isSameDay = moment(startDate).isSame(endDate, 'day');
    if (isSameDay) {
      form?.setFieldsValue({
        workerIdsMorning: undefined,
        workerIdsAfternoon: undefined,
        workerIdsEvening: undefined,
        workerIdsOther: undefined,
      });
    }

    handleChangeListDayOff();
  };

  const handleChangeTypeDate = (e: RadioChangeEvent) => {
    const value = e.target.value;
    const startDate = form?.getFieldValue('startDate');

    if (value === TypeTaskArrangement.MULTI_DAYS) {
      form?.setFieldsValue({
        // endDate is + 1 day from startDate
        endDate: moment(startDate).add(1, 'days'),
      });
    }

    if (value === TypeTaskArrangement.ONE_DAY) {
      form?.resetFields(['endDate', 'dayOffs']);
    }
  };

  return (
    <Row gutter={[20, 5]} className="mb-10">
      <Col span={12}>
        <Form.Item
          name={nameTempTypeDate}
          labelAlign="left"
          label=" "
          initialValue={TypeTaskArrangement.ONE_DAY}
          className="mb-form-0"
        >
          <Radio.Group disabled={disableStartDate && disableEndDate} onChange={handleChangeTypeDate}>
            <Row>
              <Col span={12}>
                <Radio value={TypeTaskArrangement.ONE_DAY}>1日の手配</Radio>
              </Col>
              <Col span={12}>
                <Radio value={TypeTaskArrangement.MULTI_DAYS} disabled={disableCheckBoxMultiDays}>
                  複数の手配
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const tempTypeDate = getFieldValue(nameTempTypeDate);

          const isSelectOneDay = tempTypeDate === TypeTaskArrangement.ONE_DAY;

          const span = isSelectOneDay ? 12 : 6;
          const label = isSelectOneDay ? '日付' : '開始日';

          return (
            <Col span={span}>
              <Form.Item
                label={label}
                name="startDate"
                labelAlign="left"
                rules={[rulesForm().required]}
                className="hide-supper-icon mb-form-0"
              >
                <CommonDatepicker
                  //@ts-ignore
                  inputReadOnly
                  allowClear={false}
                  onChange={handleChangeStartDate}
                  disabled={disableStartDate}
                  placeholder="YYYY/MM/DD"
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const tempTypeDate = getFieldValue(nameTempTypeDate);

          if (tempTypeDate === TypeTaskArrangement.ONE_DAY) return null;

          return (
            <Col span={6}>
              <Form.Item
                label="終了日"
                name="endDate"
                dependencies={['startDate']}
                rules={[rulesForm().required, rulesForm().validateSameDateAndStartBeforeEnd('startDate', 'endDate')]}
                labelAlign="left"
                className="hide-supper-icon mb-form-0"
              >
                <CommonDatepicker
                  //@ts-ignore
                  inputReadOnly
                  allowClear={false}
                  onChange={handleChangeEndDate}
                  disabled={disableEndDate}
                  placeholder="YYYY/MM/DD"
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const tempTypeDate = getFieldValue(nameTempTypeDate);

          if (tempTypeDate === TypeTaskArrangement.ONE_DAY) return null;

          return (
            <Col>
              <Row justify="space-between" align="middle">
                {!hideIconAdd && (
                  <IconAddDateExcludeTask
                    onClick={() => {
                      if (disableStartDate || disableEndDate) return;

                      toggleModal(true);
                    }}
                    className="mr-10"
                    disabled={disableStartDate || disableEndDate}
                  />
                )}

                <ListDayOffsSelected form={form} />
              </Row>
            </Col>
          );
        }}
      </Form.Item>

      <ModalDateExcludeTask
        visible={isOpen}
        title=" "
        onCancel={() => toggleModal(false)}
        form={form}
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
        onOk={() => toggleModal(false)}
      />
    </Row>
  );
};

export default TimeTaskPicker;
