import { Input, Spin } from 'antd';
import { uploadMultiple } from 'api/upload';
import icImage from 'assets/icons/image.svg';
import icPin from 'assets/icons/pin.svg';
import icRemove from 'assets/icons/x.svg';
import classNames from 'classnames';
import { handleErrorMessage } from 'helper';
import { useState } from 'react';
import styles from './styles.module.scss';
import { TextAreaProps } from 'antd/lib/input';

const { TextArea } = Input;

interface IPropsInput {
  onChange?: (val: any) => any;
  value?: {
    content: string;
    files?: { id: number; name: string; url: string }[];
  };
  disabled?: boolean;
  disabledFile?: boolean;
  autoSize?: TextAreaProps['autoSize'];
}

export const TextAreaWithAttachment = ({
  onChange,
  value,
  disabled,
  disabledFile,
  autoSize = { minRows: 5 },
}: IPropsInput) => {
  const [loading, setLoading] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const handleChange = (e: any) => {
    onChange!({ ...value, content: e.target.value });
  };
  const handleRemoveFile = (id: number) => {
    const newValue = {
      ...value,
      files: value?.files?.filter((x) => x.id !== id) || [],
    };
    onChange!(newValue);
  };

  const handleAddFile = async (files: any) => {
    setLoading(true);
    try {
      // add timestamp to file name
      const filesConvert = Array.from(files)?.map((file: any) => {
        const name = file?.name?.split('.');
        const ext = name?.pop();

        const fileName = `${name?.join('.')}_${new Date().getTime()}.${ext}`;

        return new File([file], fileName, { type: file.type });
      });

      const { data, domain } = await uploadMultiple([...filesConvert]);

      const newValue = { ...value };
      newValue.files = newValue.files || [];
      newValue.files = [
        ...newValue.files,
        ...data.map((url: any, i: number) => ({
          id: Math.random(),
          name: filesConvert?.[i]?.name,
          url: `${domain}/${url}`,
        })),
      ];
      onChange!(newValue);
    } catch (e) {
      handleErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePaste = async (e: any) => {
    const files = e.clipboardData.files;
    if (files?.length) {
      e.preventDefault();
      setLoading(true);
      try {
        const { data, domain } = await uploadMultiple([...files]);
        const newValue = { ...value };
        newValue.files = newValue.files || [];
        newValue.files = [
          ...newValue.files,
          ...data.map((url: any, i: number) => ({ id: Math.random(), name: url, url: `${domain}/${url}` })),
        ];
        onChange!(newValue);
      } catch (e) {
        handleErrorMessage(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDrop = async (e: any) => {
    e.preventDefault();
    setIsDrag(false);
    const files = e.dataTransfer.files;
    setLoading(true);
    try {
      const { data, domain } = await uploadMultiple([...files]);
      const newValue = { ...value };
      newValue.files = newValue.files || [];
      newValue.files = [
        ...newValue.files,
        ...data.map((url: any, i: number) => ({ id: Math.random(), name: url, url: `${domain}/${url}` })),
      ];
      onChange!(newValue);
    } catch (e) {
      handleErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setIsDrag(true);
  };

  const handleDragLeave = () => {
    setIsDrag(false);
  };

  return (
    <div style={{ cursor: loading || disabled ? 'not-allowed' : undefined }}>
      <div
        className={classNames({
          [styles.disabled]: loading || disabled,
          [styles.drag]: isDrag,
        })}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <TextArea
          autoSize={autoSize}
          onPaste={handlePaste}
          onChange={handleChange}
          className={styles.input}
          value={value?.content}
        />
        <div
          className={classNames({
            [styles.filePicker]: true,
            [styles.disabled]: disabledFile,
          })}
        >
          <div className={styles.fileList}>
            {value?.files?.map((file) => (
              <div key={file.id} className={styles.fileItem}>
                <a target="_blank" href={file.url} rel="noreferrer">
                  {file.name}
                </a>
                <img src={icRemove} className={styles.icon} onClick={() => handleRemoveFile(file.id)} alt="" />
              </div>
            ))}
          </div>
          {loading && <Spin />}
          {!disabled ? (
            <div className={styles.picker}>
              <label>
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  multiple
                  style={{ display: 'none' }}
                  onChange={(e) => handleAddFile(e.target.files)}
                />
                <img src={icPin} alt="" />
              </label>
              <label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  style={{ display: 'none' }}
                  onChange={(e) => handleAddFile(e.target.files)}
                />
                <img src={icImage} alt="" />
              </label>
            </div>
          ) : (
            <div />
          )}
        </div>
        {isDrag && (
          <div className={styles.dragMessage}>
            <span className={styles.dragMessageItem}>ここにファイルをドロップします。</span>
          </div>
        )}
      </div>
    </div>
  );
};
