import { checkValuesChanged } from 'helper';

const useCheckDataUpdateChanged = () => {
  const checkDataUpdateChanged = ({ currentData, initialData }: { currentData: any; initialData: any }) => {
    // covert to boolean to compare
    const initialMorningShift = !!(initialData as any)?.morningShift;
    const initialAfternoonShift = !!(initialData as any)?.afternoonShift;
    const initialEveningShift = !!(initialData as any)?.eveningShift;
    const initialOtherShift = !!(initialData as any)?.otherShift;

    const currentMorningShift = !!currentData?.morningShift;
    const currentAfternoonShift = !!currentData?.afternoonShift;
    const currentEveningShift = !!currentData?.eveningShift;
    const currentOtherShift = !!currentData?.otherShift;

    const initialName = (initialData as any)?.name ?? '';
    const currentName = currentData?.name ?? ''; //

    const initialDescription = {
      ...((initialData as any)?.description ?? {}),
      content: (initialData as any)?.description?.content?.trim(),
    };
    const currentDescription = {
      ...(currentData?.description ?? {}),
      content: currentData?.description?.content?.trim(),
    };
    const initialNoteConstructionSite = (initialData as any)?.noteConstructionSite?.trim() ?? '';
    const currentNoteConstructionSite = currentData?.noteConstructionSite?.trim();

    const { hasChanged, differentKeys } = checkValuesChanged({
      initialValues: {
        ...initialData,
        name: initialName,
        morningShift: initialMorningShift,
        afternoonShift: initialAfternoonShift,
        eveningShift: initialEveningShift,
        otherShift: initialOtherShift,
        description: initialDescription,
        noteConstructionSite: initialNoteConstructionSite,
      },
      currentValues: {
        ...currentData,
        name: currentName,
        morningShift: currentMorningShift,
        afternoonShift: currentAfternoonShift,
        eveningShift: currentEveningShift,
        otherShift: currentOtherShift,
        description: currentDescription,
        noteConstructionSite: currentNoteConstructionSite,
      },
    });

    return { hasChanged, differentKeys };
  };

  return { checkDataUpdateChanged };
};

export default useCheckDataUpdateChanged;
