import { ICompanyProfile, ITaskDetail } from 'common/interface';
import { roundNumberWorkerTask } from 'helper';
import { isNil } from 'lodash';
import moment from 'moment';

const useFillDefaultTime = () => {
  // Nếu task được tạo bởi worker
  // TH1 : đăng ký 1 ca -> 1 thời gian mong muốn của cusotmer -> fill vào theo customer nếu có || fill theo thời gian làm việc đăng ký của công ty
  // TH2 : đăng ký nhiều ca -> fill theo thời gian làm việc đăng ký của công ty
  const handleFillDefaultTime = ({
    formValue,
    profileCompany,
    startTimeDesire,
    endTimeDesire,
  }: {
    formValue: Record<string, any>;
    profileCompany: ICompanyProfile;
    startTimeDesire: string;
    endTimeDesire: string;
  }) => {
    formValue.workerMorning = roundNumberWorkerTask(formValue.maxWorkerMorning ?? formValue.maxWorker);
    formValue.workerAfternoon = roundNumberWorkerTask(formValue.maxWorkerAfternoon ?? formValue.maxWorker);
    formValue.workerEvening = roundNumberWorkerTask(formValue.maxWorkerEvening);
    formValue.workerOther = roundNumberWorkerTask(formValue.maxWorkerOther);

    const isCheckOneShift =
      [formValue?.morningShift, formValue?.afternoonShift, formValue?.eveningShift, formValue?.otherShift].filter(
        Boolean
      ).length === 1;

    // Đăng ký 1 ca
    if (isCheckOneShift) {
      if (formValue?.morningShift) {
        const timeFrom = !isNil(startTimeDesire) ? moment(startTimeDesire, 'HH:mm') : profileCompany?.morningFrom;

        const timeTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.morningTo;

        formValue.morningFrom = timeFrom;
        formValue.morningTo = timeTo;
      }

      if (formValue?.afternoonShift) {
        const timeFrom = !isNil(startTimeDesire) ? moment(startTimeDesire, 'HH:mm') : profileCompany?.afternoonFrom;

        const timeTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.afternoonTo;

        formValue.afternoonFrom = timeFrom;
        formValue.afternoonTo = timeTo;
      }

      if (formValue?.eveningShift) {
        const timeFrom = !isNil(startTimeDesire) ? moment(startTimeDesire, 'HH:mm') : profileCompany?.eveningFrom;

        const timeTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.eveningTo;

        formValue.eveningFrom = timeFrom;
        formValue.eveningTo = timeTo;
      }

      if (formValue?.otherShift) {
        const timeFrom = !isNil(startTimeDesire) ? moment(startTimeDesire, 'HH:mm') : profileCompany?.otherFrom;

        const timeTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.otherTo;

        formValue.otherFrom = timeFrom;
        formValue.otherTo = timeTo;
      }
    } else {
      /* ---------------------------- Đăng ký nhiều ca ---------------------------- */
      const isCheckMorningAndAfternoon =
        [formValue?.morningShift, formValue?.afternoonShift].every(Boolean) && !formValue?.eveningShift;

      const isCheckAfternoonAndEvening =
        [formValue?.afternoonShift, formValue?.eveningShift].every(Boolean) && !formValue?.morningShift;
      const isCheckMorningAndEvening =
        [formValue?.morningShift, formValue?.eveningShift].every(Boolean) && !formValue?.afternoonShift;
      const isCheckMorningAndAfternoonAndEvening = [
        formValue?.morningShift,
        formValue?.afternoonShift,
        formValue?.eveningShift,
      ].every(Boolean);

      const isCheckMorningAndOther =
        [formValue?.morningShift, formValue?.otherShift].every(Boolean) &&
        !formValue?.afternoonShift &&
        !formValue?.eveningShift;

      const isCheckAfternoonAndOther =
        [formValue?.afternoonShift, formValue?.otherShift].every(Boolean) &&
        !formValue?.morningShift &&
        !formValue?.eveningShift;

      const isCheckEveningAndOther =
        [formValue?.eveningShift, formValue?.otherShift].every(Boolean) &&
        !formValue?.morningShift &&
        !formValue?.afternoonShift;

      if (isCheckMorningAndAfternoon) {
        formValue.morningFrom = !isNil(startTimeDesire)
          ? moment(startTimeDesire, 'HH:mm')
          : profileCompany?.morningFrom;

        formValue.afternoonTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.afternoonTo;
      }

      if (isCheckAfternoonAndEvening) {
        formValue.afternoonFrom = !isNil(startTimeDesire)
          ? moment(startTimeDesire, 'HH:mm')
          : profileCompany?.afternoonFrom;

        formValue.eveningTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.eveningTo;
      }

      if (isCheckMorningAndEvening || isCheckMorningAndAfternoonAndEvening) {
        formValue.morningFrom = !isNil(startTimeDesire)
          ? moment(startTimeDesire, 'HH:mm')
          : profileCompany?.morningFrom;

        formValue.eveningTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.eveningTo;
      }

      if (isCheckMorningAndOther) {
        formValue.morningFrom = !isNil(startTimeDesire)
          ? moment(startTimeDesire, 'HH:mm')
          : profileCompany?.morningFrom;

        formValue.morningTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.morningTo;
      }

      if (isCheckAfternoonAndOther) {
        formValue.afternoonFrom = !isNil(startTimeDesire)
          ? moment(startTimeDesire, 'HH:mm')
          : profileCompany?.afternoonFrom;

        formValue.afternoonTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.afternoonTo;
      }

      if (isCheckEveningAndOther) {
        formValue.eveningFrom = !isNil(startTimeDesire)
          ? moment(startTimeDesire, 'HH:mm')
          : profileCompany?.eveningFrom;

        formValue.eveningTo = !isNil(endTimeDesire) ? moment(endTimeDesire, 'HH:mm') : profileCompany?.eveningTo;
      }
    }
  };

  return { handleFillDefaultTime };
};

export default useFillDefaultTime;
