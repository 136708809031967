import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { UserRole } from 'common';
import { IResConstructionSiteDetail } from 'common/interface';
import { CommonSelect } from 'components/CommonSelect';
import { ISelectProps } from 'components/CommonSelect';

interface IProps extends ISelectProps<any> {
  constructionSiteDetail?: IResConstructionSiteDetail;
  form: FormInstance;
  setLeader: (value: any) => void;
}

const SelectLeaderTask = ({ constructionSiteDetail, form, setLeader, ...rest }: IProps) => {
  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const workerIdsMorning = getFieldValue('workerIdsMorning');
        const workerIdsAfternoon = getFieldValue('workerIdsAfternoon');
        const workerIdsEvening = getFieldValue('workerIdsEvening');
        const workerIdsOther = getFieldValue('workerIdsOther');
        const leaderId = getFieldValue('leaderId');

        const leaderConstructionSiteIds = constructionSiteDetail?.data?.workers
          ?.filter((el: any) => el.roleId === UserRole.LEADER)
          ?.map((item: any) => item?.id);

        const workerIds = [
          ...(workerIdsMorning || []),
          ...(workerIdsAfternoon || []),
          ...(workerIdsEvening || []),
          ...(workerIdsOther || []),
          ...(leaderConstructionSiteIds || []),
        ];

        if (constructionSiteDetail && leaderId && (workerIds?.length === 0 || !workerIds.includes(leaderId))) {
          form.setFieldsValue({
            leaderId: undefined,
          });
          setLeader(undefined);
        }

        let allWorkers = constructionSiteDetail?.data?.workers;
        allWorkers = allWorkers?.filter((worker: any, index: number) => workerIds.includes(worker?.id));

        return (
          <Form.Item label="プロジェクトリーダー" name="leaderId">
            <CommonSelect filterOption={true} allowClear={true} options={allWorkers || []} {...rest} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default SelectLeaderTask;
