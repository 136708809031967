import useProfile from './useProfile';
import { ITaskDetail, IVendor } from 'common/interface';
import { CommonStatus, ConstructionSiteType, UserRole } from 'common';
import { isNil } from 'lodash';
import { ROLES_ADMIN } from 'common/const';
import useGetRoleInCompany from 'pages/Tasks/hooks/useGetRoleInCompany';

type TVendorField = 'vendorMorning' | 'vendorEvening' | 'vendorAfternoon' | 'vendorOther';

interface IProps {
  vendor?: { [key in TVendorField]?: IVendor[] };
  taskDetail?: ITaskDetail;
}

// handle view component in task detail | task update

const useViewTask = ({ vendor, taskDetail }: IProps) => {
  const profile = useProfile();

  const { currentRoleInCompany, isVendorCustomer, isVendor } = useGetRoleInCompany({ taskDetail });

  const isCanViewTime = (checked: boolean, type: 'morningShift' | 'afternoonShift' | 'eveningShift' | 'otherShift') => {
    if (!checked) return false;

    const isVendorOfTaskMorning = vendor?.vendorMorning?.some((x: any) => x?.userId === profile?.id);
    const isVendorOfTaskEvening = vendor?.vendorEvening?.some((x: any) => x?.userId === profile?.id);
    const isVendorOfTaskAfternoon = vendor?.vendorAfternoon?.some((x: any) => x?.userId === profile?.id);
    const isVendorOfTaskOther = vendor?.vendorOther?.some((x: any) => x?.userId === profile?.id);

    const isVendorOfTask =
      type === 'morningShift'
        ? isVendorOfTaskMorning
        : type === 'eveningShift'
        ? isVendorOfTaskEvening
        : type === 'afternoonShift'
        ? isVendorOfTaskAfternoon
        : isVendorOfTaskOther;

    const isCustomerOfTask =
      (isVendorCustomer || currentRoleInCompany === UserRole.CUSTOMER) && taskDetail?.customerId === profile?.id;

    const isCustomer = currentRoleInCompany === UserRole.CUSTOMER;

    if (isCustomer || isCustomerOfTask) {
      return [taskDetail?.startTime, taskDetail?.endTime].every((item) => !isNil(item));
    }

    if (isVendorCustomer || isVendor) {
      return isVendorOfTask && [taskDetail?.startTime, taskDetail?.endTime].every((item) => !isNil(item));
    }

    return true;
  };

  const isCanViewOtherField = {
    morningShift:
      currentRoleInCompany !== UserRole.VENDOR || vendor?.vendorMorning?.some((x: any) => x?.userId === profile?.id),
    afternoonShift:
      currentRoleInCompany !== UserRole.VENDOR || vendor?.vendorAfternoon?.some((x: any) => x?.userId === profile?.id),
    eveningShift:
      currentRoleInCompany !== UserRole.VENDOR || vendor?.vendorEvening?.some((x: any) => x?.userId === profile?.id),
    otherShift:
      currentRoleInCompany !== UserRole.VENDOR || vendor?.vendorOther?.some((x: any) => x?.userId === profile?.id),
  };

  const isCanViewMaxWorker = ROLES_ADMIN.includes(currentRoleInCompany);

  // check if can view certificate customer
  const isCanViewCertificateCustomer = () => {
    // nếu công trường là fix price thì ẩn mục maxWorker + License -> ẩn đi
    const isConstructionFixPrice = taskDetail?.constructionSiteType === ConstructionSiteType.FIXED_PRICE;
    if (isConstructionFixPrice) return false;

    // vendor_customer : Chỉ hiển thị khi nó là customer của task
    if (isVendorCustomer && taskDetail?.customerId === profile?.id) {
      return true;
    }

    // Customer : Chỉ hiển thị với customer của task
    if (currentRoleInCompany === UserRole.CUSTOMER && taskDetail?.customerId === profile?.id) {
      return true;
    }

    if ([UserRole.ADMIN, UserRole.LEADER, UserRole.OFFICE_MANAGER, UserRole.MANAGER].includes(currentRoleInCompany)) {
      return true;
    }

    return false;
  };

  const isCanViewShifts = () => {
    // vendor_customer : Chỉ hiển thị khi nó là customer của task
    if (isVendorCustomer && taskDetail?.customerId === profile?.id) {
      return true;
    }

    // Customer : Chỉ hiển thị với customer của task
    if (currentRoleInCompany === UserRole.CUSTOMER && taskDetail?.customerId === profile?.id) {
      return true;
    }

    if ([UserRole.ADMIN, UserRole.LEADER, UserRole.OFFICE_MANAGER, UserRole.MANAGER].includes(currentRoleInCompany)) {
      return true;
    }

    return false;
  };

  const STAFF_ROLES = [UserRole.VENDOR_WORKER, UserRole.WORKER, UserRole.OTHER_WORKER, UserRole.PART_TIME_WORKER];
  const isCanViewMorningShift = () => {
    const isAdmin = ROLES_ADMIN.includes(currentRoleInCompany);

    if (isAdmin) return true;

    // other roles : VENDOR_WORKER | WORKER | OTHER_WORKER | PART_TIME_WORKER
    const isStaff = STAFF_ROLES.includes(currentRoleInCompany);

    const isWorkerOfMorning = taskDetail?.workers?.some(
      (worker) => worker?.userId === profile?.id && worker?.morningShift === CommonStatus.ACTIVE
    );

    if (isStaff) {
      return isWorkerOfMorning;
    }

    return true;
  };
  const isCanViewAfternoonShift = () => {
    const isAdmin = ROLES_ADMIN.includes(currentRoleInCompany);

    if (isAdmin) return true;

    // other roles : VENDOR_WORKER | WORKER | OTHER_WORKER | PART_TIME_WORKER
    const isStaff = STAFF_ROLES.includes(currentRoleInCompany);

    const isWorkerOfAfternoon = taskDetail?.workers?.some(
      (worker) => worker?.userId === profile?.id && worker?.afternoonShift === CommonStatus.ACTIVE
    );

    if (isStaff) {
      return isWorkerOfAfternoon;
    }

    return true;
  };
  const isCanViewEveningShift = () => {
    const isAdmin = ROLES_ADMIN.includes(currentRoleInCompany);

    if (isAdmin) return true;

    // other roles : VENDOR_WORKER | WORKER | OTHER_WORKER | PART_TIME_WORKER
    const isStaff = STAFF_ROLES.includes(currentRoleInCompany);

    const isWorkerOfEvening = taskDetail?.workers?.some(
      (worker) => worker?.userId === profile?.id && worker?.eveningShift === CommonStatus.ACTIVE
    );

    if (isStaff) {
      return isWorkerOfEvening;
    }

    return true;
  };

  const isCanViewOtherShift = () => {
    const isAdmin = ROLES_ADMIN.includes(currentRoleInCompany);

    if (isAdmin) return true;

    // other roles : VENDOR_WORKER | WORKER | OTHER_WORKER | PART_TIME_WORKER
    const isStaff = STAFF_ROLES.includes(currentRoleInCompany);

    const isWorkerOfOther = taskDetail?.workers?.some(
      (worker) => worker?.userId === profile?.id && worker?.otherShift === CommonStatus.ACTIVE
    );

    if (isStaff) {
      return isWorkerOfOther;
    }

    return true;
  };

  return {
    isCanViewTime,
    isCanViewOtherField,
    isCanViewMaxWorker,
    isCanViewCertificateCustomer,
    isCanViewMorningShift,
    isCanViewAfternoonShift,
    isCanViewEveningShift,
    isCanViewOtherShift,
    isCanViewShifts,
  };
};

export default useViewTask;
