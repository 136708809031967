import { Select } from 'antd';
import { getListConstructionSiteMultiCompany } from 'api/constructionSite';
import { UserRole } from 'common';
import { ICompanyActive, IProject } from 'common/interface';
import queryKeys from 'common/queryKeys';
import { CommonSelect, ISelectProps } from 'components/CommonSelect';
import { getRoleName, handleOnPopupScroll } from 'helper';
import useListProjectTask from 'hooks/apis/useListProjectTask';

import { isNil, uniqBy } from 'lodash';
import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';

interface IProps extends ISelectProps<any> {
  filters?: Record<string, any>;
  defaultValue?: Record<string, any>;
  keyUniq?: string;
  companySelected?: ICompanyActive;
  companyIds?: number[];
}

export const convertFullNameProject = (project: IProject) => {
  if (isNil(project)) return '';

  return `${project?.name}_${project?.companyName} (${getRoleName(project?.userHandleRoleId)})`;
};

const SelectProject = ({ filters, defaultValue, keyUniq = 'id', companySelected, ...rest }: IProps) => {
  const isOnlyVendor = companySelected?.roleId === UserRole.VENDOR && !companySelected.isCustomer;

  const {
    data: dataRes,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [queryKeys.task.list_constructionSite__create_task, filters],
    queryFn: ({ pageParam = 1 }) => {
      return getListConstructionSiteMultiCompany({
        ...filters,
        pageIndex: pageParam,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      const { pageIndex, totalPages } = lastPage;

      if (pageIndex >= totalPages) {
        return undefined;
      }

      return pageIndex + 1;
    },
    ...rest,
  });

  const data = (dataRes?.pages?.map((page) => page?.data)?.reduce((arr, data) => [...arr, ...data], []) ||
    []) as IProject[];
  const countNameProjects = dataRes?.pages?.[0]?.countNameProjects ?? ({} as { [projectName: string]: number });

  // store data to react-query
  const { setListProjectTask } = useListProjectTask();
  const dataWithDefault = !isNil(defaultValue) && !filters?.keyword ? uniqBy([defaultValue, ...data], keyUniq) : data;
  const dataWithDefaultAndFullName = dataWithDefault.map((project: any) => ({
    ...project,
    fullName: convertFullNameProject(project),
  }));

  useEffect(() => {
    setListProjectTask(dataWithDefaultAndFullName);
  }, [dataWithDefaultAndFullName, setListProjectTask]);

  return (
    <CommonSelect
      optionLabel="fullName"
      filterOption={false}
      options={dataWithDefaultAndFullName.map((project: any) => ({
        ...project,
        isDuplicate: countNameProjects?.[project?.name] > 1, // check if duplicate project name
      }))}
      disabled={isOnlyVendor}
      onPopupScroll={(e) => {
        handleOnPopupScroll(() => {
          if (isLoading || isFetching) return;

          hasNextPage && fetchNextPage();
        });
      }}
      listHeight={256}
      loading={isLoading || isFetching}
      {...rest}
    />
  );
};

export default SelectProject;
