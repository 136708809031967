import { IDetailsEditTask } from 'common/interface';
import { axiosInstance, sendGet } from './axios';

// eslint-disable-next-line import/prefer-default-export
export const getListTask = (params: any) => sendGet('/v1/app/task/list', params);

export const getDetailsEdit = async (taskId: number, companyId?: number) => {
  const response = await axiosInstance.get<IDetailsEditTask>(`/v5/task/list-history-customer-update-task/${taskId}`, {
    headers: { companyId },
  });

  return response?.data;
};

export const sendEmail = async ({ data, companyId }: { data: FormData; companyId?: number }) => {
  const response = await axiosInstance.post('/nest/task/send-mail', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      companyId,
    },
  });

  return response?.data;
};

export const createTaskWhenChangeConstructionSite = async ({
  taskId,
  payload,
  companyId,
}: {
  taskId: number;
  payload: Record<string, any>;
  companyId?: number;
}) => {
  const response = await axiosInstance.post(`/v2/task/change-project/${taskId}`, payload, {
    headers: { companyId },
  });

  return response?.data;
};
