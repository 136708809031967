import { Select, SelectProps, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { TYPE_INPUT } from 'common';
import { StaffTag } from 'components/StaffTag';
import classnames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import chevronDown from 'assets/icons/chevron-down-black.svg';
import { getRoleName } from 'helper';

const { Option } = Select;
export interface ISelectProps<VT> extends SelectProps<VT> {
  optionLabel?: string;
  optionValue?: string | number;
  optionVendor?: string;
  optionAvatar?: string;
  mode?: TYPE_INPUT.MULTIPLE | undefined;
  options?: any;
  selected?: any;
  className?: string;
  hideIcon?: boolean;
  showRolesInCompany?: boolean;
  variants?: 'multiple';
}

export function CommonSelect<VT extends SelectValue = SelectValue>({
  optionLabel = 'name',
  optionValue = 'id',
  optionVendor = 'vendor',
  optionAvatar = 'avatar',
  mode = undefined,
  allowClear = false,
  options = [],
  selected,
  className,
  hideIcon = false,
  loading,
  suffixIcon,
  showRolesInCompany = false,
  variants,
  ...rest
}: ISelectProps<VT>) {
  let value = selected ? selected : !mode ? '' : [];

  const filterOption = (input: any, option: any) => {
    if (option?.children?.props?.name) {
      return option?.children?.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } else {
      return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  };

  const getSuffixIcon = () => {
    if (hideIcon) return null;

    // if (loading) {
    //   return <Spin size="small" />;
    // }

    return suffixIcon ? suffixIcon : <img src={chevronDown} width={12} height={12} alt="" />;
  };

  return (
    <Select<VT>
      className={classnames(
        styles.baseselect,
        {
          [styles.loading]: loading,
          [styles.variantsMultiple]: variants === 'multiple',
          [styles.multiple]: mode === TYPE_INPUT.MULTIPLE && variants !== 'multiple',
        },
        className
      )}
      // defaultValue={value}
      showSearch
      allowClear={allowClear}
      listHeight={256}
      filterOption={filterOption}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      mode={mode}
      // maxTagCount="responsive"
      suffixIcon={getSuffixIcon()}
      showArrow
      loading={loading}
      {...rest}
    >
      {options?.map((option: any, index: number) => {
        if (option.hasOwnProperty(optionAvatar)) {
          // const a = getRoleName(option?.roleId);

          const roleInCompany = getRoleName(option?.roleId ?? option?.userCompany?.roleId);

          return (
            <Option
              key={`${option[optionValue]}_${index}`}
              value={option[optionValue]}
              className={styles.multioption}
              disabled={option?.disabled}
              // disabled={option?.isBusy}
            >
              <StaffTag
                avatar={option[optionAvatar]}
                name={option[optionLabel]}
                vendor={option[optionVendor]}
                disabled={option?.disabled || option?.isBusy}
                showRolesInCompany={showRolesInCompany}
                roleInCompany={roleInCompany}
              />
            </Option>
          );
        } else {
          return (
            <Option key={`${option[optionValue]}_${index}`} value={option[optionValue]}>
              {option[optionLabel]} {option?.isDuplicate && <span> - {option?.customerCompanyName}</span>}
            </Option>
          );
        }
      })}

      {loading && (
        <div className={styles.loadingWrap}>
          <Spin size="small" />
        </div>
      )}
    </Select>
  );
}
